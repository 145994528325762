.announce {
  margin: 0 0 calc(var(--gutter) * 2);
  font-size: var(--font_size_1);
  line-height: 1.4;
  padding: .5rem 2rem;
  border: 3px solid #f00431;
  border-radius: 4px;
  background: #ffffc9;

  @media (--medium-plus) {
    margin: 0 auto var(--gutter);
    max-width: calc(var(--page_max_width) - var(--gutter) * 2);
  }

  &.no-border-bottom {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  a {
    color: red !important;
  }
}

.index .announce {
  margin-left: var(--half-gutter);
  margin-right: var(--half-gutter);

  @media (--medium-plus) {
    margin-left: auto;
    margin-right: auto;
  }
}

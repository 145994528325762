.c-xmas-greeting {
  border: 3px solid #f00431;
  border-radius: 8px;

  @media (--medium-plus) {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(var(--page_max_width) - var(--gutter) * 2);
    background: #c6e7ed url(/static/i/xmas_bg-L.jpg) 0 100% no-repeat;
    background-size: auto 225px;
  }
}

.index .c-xmas-greeting {
  margin: 0 var(--half-gutter) 2rem;

  @media (--medium-plus) {
    margin-left: auto;
    margin-right: auto;
  }
}

.xmas-greeting {
  padding: 2rem 1.5rem;
  text-align: center;
  border-radius: 8px;
  background: url(/static/i/winter_bg.jpg) 0 0;
  background-size: cover;

  @media (--medium-plus) {
    min-height: 225px;
    padding: 3rem;
    border-width: 4px;
    background: url(/static/i/xmas_bg-R.jpg) 100% 0 no-repeat;
    background-size: auto 225px;
    color: #582e31;
  }

  p {
    margin: 0;
  }

  p.separated {
    margin: 1rem 0;
  }

/*
  p {
    display: table;
    padding: .3rem .6rem;
    text-align: center;
    background: white;

    @media (--medium-plus) {
      margin: 1rem auto;
    }
  }*/
}

.right-angles-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.right-angles-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.xmas-greeting__hdr {
  margin-bottom: 1rem;
  font-size: 2.5rem;
  color: #f00431;

  @media (--medium-plus) {
    margin: 1rem auto;
    width: auto;
  }
}

.xmas-greeting span {
  display: inline !important;
  padding: .2rem 0;
  box-shadow: .5rem 0 0 #fff, -.5rem 0 0 #fff;
  box-decoration-break: clone;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
}

.xmas-greeting__special {
  margin-bottom: 1rem;

  span {
    box-shadow: .5rem 0 0 #f00431, -.5rem 0 0 #f00431;
    font-weight: bold;
    color: white;
    background: #f00431 !important;
  }
}
/* стили для десктопа идут одним блоком внизу */
.l-main-menu {
  min-width: var(--page_min_width);

  ul,
  li {
    list-style: none;
  }

  li {
    list-style: none;
  }
}

.main-menu {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: .5rem 1rem;

  min-width: var(--page_min_width);
  max-width: var(--page_max_width);

  background: var(--cl_bg_pink_light);

  a,
  a:active {
    color: var(--cl_gray_dark);
    text-decoration: none;
  }

  a:hover {
    color: black;
    border-color: transparent;
  }
}

.menu__submenu {
  display: none;
  width: 100%;
  padding-left: 0;

  ul {
    padding-left: 0;
  }
}

.is-active .menu__submenu-l1 {
  position: absolute;
  z-index: 100;
  left: 0;
  display: block;
  margin-top: .5rem;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .3);
  background: white;
}

.menu__item-header {
  padding: 1rem;
  border-bottom: 1px solid var(--cl_border_gray_x-light);
  line-height: 1.6rem;

  @nest .menu__item-l2 & {
    position: relative;
  }

  @nest .menu__item-l2.is-active & {
    font-weight: bold;

    a,
    a:active {
      color: black;
    }
  }

  @nest .menu__item-l2.menu__item-w-children.is-active & {
    border-bottom-color: transparent;
  }

  a {
    display: block;
    border-bottom-color: transparent;

    &:hover {
      color: var(--cl_carmine_dark);
    }
  }

  @nest .is-active & a:active {
    color: black;
  }

  @nest .menu__item-w-children & a:after {
    content: '\25b6\fe0e';
    position: absolute;
    right: 0;
    margin-right: 1rem;
    font-size: var(--font_size_-5);
    color: var(--cl_gray);
  }

  @nest .menu__item-w-children.is-active & a:after {
    content: '\25bc';
  }

}

.menu__item-l1 {
  > .menu__item-header {
    margin: .5rem 0;
    padding: 0;
    text-transform: capitalize;
    font-size: var(--font_size_-1);
    font-weight: bold;
    border-bottom: none;
    color: black;

    @media (--small-plus) {
      font-size: var(--font_size_base);
    }

    @media (--large) {
      text-transform: none;
    }
  }

  @nest .is-activated & > .menu__item-header > a {
    color: var(--cl_carmine_light);
  }

  @nest .is-activated &.is-active > .menu__item-header > a {
    color: black;
  }
}

.menu__submenu-l2 {
  display: none;
  padding: .5rem 0;

  @nest .menu__item-l2.is-active & {
    display: block;
    padding: 0 0 1rem 4rem;
    border-bottom: 1px solid var(--cl_border_gray_x-light);
  }

  .menu__item {
    display: block;
    margin: 1rem 0;
    line-height: 1.5rem;
  }

  a {
    display: inline;
    padding: 0;
  }
}

.menu__item-l3 {
  &:first-child {
    margin-top: .5rem;
  }
}

@media (--medium-plus) {
  .l-main-menu {
    border-bottom: 1px solid var(--cl_gray_x-light);
  }

  .main-menu {
    padding: .5rem var(--gutter) 0;
    background: none;
  }

  .menu__item-header {
    a {
      display: inline;
      color: var(--cl_gray);

      &:hover {
        color: black;
        border-bottom-color: currentColor;
      }
    }
  }

  .menu__item-l1 {
    position: relative;
    width: 20%;
    border-top: 3px solid white;
    vertical-align: top;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: calc(100% - 5px);
      border-right: 1px dotted var(--cl_gray_light);
    }

    > .menu__item-header {
      margin-top: 0;
      padding: .5rem 0;
      text-align: center;
      font-size: var(--font_size_1);
      font-weight: normal;
    }

    &:first-child > .menu__item-header {
      border-left: 1px dotted var(--cl_gray_light);
    }

  }

  .menu__item-l1:not(.menu__item-static):hover {
    border: 3px solid red;
    border-bottom: none;

    &:after {
      content: none;
    }

    > .menu__item-header {
      position: relative;
      padding: 1rem 0 0 5px;
      border-left: none !important;
      font-weight: bold;

      &:before {
        content: ' ';
        position: absolute;
        left: -4px;
        top: 0;
        width: 1px;
        height: calc(100% + 5px);
        z-index: 30000;
        background: white;
      }

      &:after {
        content: ' ';
        position: absolute;
        left: 0;
        bottom: -8px;
        z-index: 30000;
        width: 100%;
        height: 3px;
        background: white;
      }
    }
  }

  .menu__item-l1:hover > .menu__submenu-l1 {
    display: block;
  }

  .menu__item-l1:hover > .menu__submenu-2-columns,
  .menu__item-l1:hover > .menu__submenu-3-columns {
    display: flex;
  }

  .menu__submenu-l1 {
    position: absolute;
    z-index: 9999;
    width: auto;
    min-width: calc(100% + 6px);
    margin-top: 0;
    margin-left: -3px;
    padding: 1.5rem;
    border: 3px solid var(--cl_border_error_red);
    font-size: var(--font_size_base);
    line-height: normal;
    background: white;

    &.menu__submenu-2-columns {
      min-width: calc(200% + 12px);
    }
  
    &.menu__submenu-3-columns {
      min-width: calc(350% + 21px);
    }  
  }

  .menu__column {
    flex: 1 1 0;
    padding: 0 1.5rem;
  }

  .menu__item-l2  {
    .menu__item-header {
      padding: 1rem 0 .5rem;
      border-bottom-color: transparent;
      font-weight: bold;

      a {
        display: inline;

        &:after {
          content: none;
        }
      }
    }

    &.menu__item-w-children .menu__item-header {
      border-bottom-color: var(--cl_gray_x-light);
    }
  }

  .menu__item-l3 {
    a:hover {
      border-bottom-color: currentColor;
    }
  }

  .menu__submenu-l2 {
    display: block;
    padding: 1rem 0;
    font-size: var(--font_size_-1);

    .menu__item {
      margin: 0 0 .5rem;
    }
  }

}

.menu__item-sale {
  a {
    color: var(--cl_text_sale_red);
    @media (--medium-plus) {
      &:before {
        content:'%';
        margin-right: .5rem;
      }
      &:after {
        content:'%';
        margin-left: .5rem;
      }
    }
  }
}

.menu-entire {
  width: 100%;
  padding: 0 1.5rem;
}

.menu__item-brands {
  .menu__submenu-l1 {
    right: -3px;
    width: 400%;
  }

  .menu__submenu-l2 {
    column-count: 4;
  }
}
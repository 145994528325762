.product__properties {
  line-height: 1.6;

  ul {
    padding-left: 0;
    @media (--medium-plus) {
      padding-left: 1.5rem;
    }
  }

  ul ul {
    padding-left: 1.5rem;
  }

  li {
    margin: 1.5rem 0;
    padding-left: 1rem;
  }
}

.product__properties--short {
  display: none;

  @media (--medium-plus) {
    display: block;
    grid-area: properties;
    padding: 0 var(--gutter);
  }

  &.refined {
    overflow-y: hidden;
    position: relative;

    &:after {
      position: absolute;
      z-index: 9; /* всплывающие подсказки balloon имеют z-index 10 */
      content: '';
      width: 100%;
      min-height: 100px;
      height: 30%;
      left: 0;
      bottom: 0;
      background: linear-gradient(to top, white 20px, transparent);
      pointer-events: none;
    }
  }
}

.product__properties .full-bleed {
  margin-left: -4rem;
  width: calc(100% + 5rem);

  @media (--medium-plus) {
    margin-left: 0;
    width: 100%;
  }
}

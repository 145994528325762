.l-breadcrumbs {
  margin: 0 auto;
  min-width: var(--page_min_width);
  max-width: var(--page_max_width);
  width: 100%;

  @media (--small) {
    display: none;
  }

  @media (--medium-plus) {
    margin: 1.5rem auto 0;
    padding: .5rem var(--gutter);
  }
}

.breadcrumbs {
  margin: 0 auto;
  padding: 0.75rem 1rem;
  list-style: none;
  font-size: var(--font_size_-2);
  line-height: 1.5;
  background-color: var(--cl_bg_gray_light);
  border-radius: 4px;

  @media (--medium-plus) {
    padding: .75rem 1.5rem;
    font-size: var(--font_size_base);
  }

  li {
    display: inline-block;
    color: var(--cl_gray);
  }

  li:not(:last-child)::after {
    position: relative;
    content: "\2192";
    margin: 0 0.25rem;

    @media (--medium-plus) {
      margin: 0 .5rem;
    }
  }

  a {
    color: var(--cl_gray);
  }
}

.product__info-links {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--medium-plus) {
    grid-area: more;
    flex-wrap: wrap;
  }
}

.product__info-anchors {
  padding: 1rem 0 1.5rem;
  font-size: var(--font_size_1);
  line-height: 2.2;
  width: 100%;

  @media (--medium-plus) {
    /*
    grid-area: more;
    */
    margin: var(--half-gutter) var(--gutter);
    border-bottom: 1px dotted var(--cl_gray_x-light);
    text-align: center;
    font-size: var(--font_size_base);
    line-height: 2;
  }
}

.comments-anchor {
  font-weight: bold;

  @media (--medium-plus) {
    &:before {
      content: '\1F4AC';
      margin-right: .5rem;
    }
  }
}
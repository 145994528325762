.product-list-block {
    padding: 0 1rem 2rem;
  
    &:first-child {
        margin-top: -1rem;
    }
  
    @media (--medium-plus) {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: var(--page_max_width);
        padding-left: var(--gutter);
        padding-right: var(--gutter);
        &:after {
            content: '';
            display: block;
            height: 10px;
            box-shadow: 0 10px 0 0 var(--cl-border-x-light);
        }
        &.is-last:after {
            height: 0;
        }
    }
  }

.product-list-block__header {
    margin-bottom: 1em;
    padding: .5em;
    font-size: var(--font_size_8);
    font-weight: normal;
    text-align: center;
    color: var(--cl_gray_dark);
    background: url(/static/i/section_hdr_bg.jpg) 50% 40% no-repeat;
    background-size: auto 45px;
  
    .index & {
        margin-top: .5em;
    }
  
    @media (--medium-plus) {
        margin-top: 1em;
    }
  
    a:link, a:visited {
        color: var(--cl_gray_dark);
        border-bottom: 1px solid currentColor;
    }
  
    a:hover {
        color: var(--cl_link_hover);
    }
}

.product-list-block__content {
    ul {
        margin-block-end: 1em;
        padding: 0;
        list-style-type: none;
        line-height: 1.5;
        font-size: var(--font-1);

        @media (--medium-below) {
            margin-block-start: .5em;
            padding-top: .75em;
            border-top: 1.5px solid var(--cl-border-x-light);
        }

        @media (--medium-plus) {
            margin-block-start: -.5em;
        }
    
        a {
            @mixin a-perm;
        }
    }
    
    li {
        &:before {
            content: '\2022';
            margin-right: 1.2rem;
            color: var(--cl-text-base);
        }
        @media (--medium-plus) {
            display: inline-block;
            margin-left: 1rem;
            &.--first:before {
                content: none;
            }
        }
    }
}

@media (--medium-plus) {
    .index .product-list-block__header {
        order: 1;
    }
    .index .product-list-block__content {
        order: 2;
    }
    .index .product-list-block__products {
        order: 3;
    }
    .index .product-list-block__more-link {
        order: 4;
        display: block;
    }
    .index .product-list-block::after {
        order: 999;
    }    
}

.product-list-block__products {
    .c-product:nth-child(5n) {
        @media (--large-below) {
            display: none;
        }
    }
}

.product-list-block__more-link {
    margin-block-end: 1em;
    text-align: right;
    font-size: var(--font-1);
    font-weight: bold;
  
    a:after {
        content: '\2192';
        margin: 0 .5rem;
    }
}

.product__msg {
  margin-top: 1rem;
  line-height: 1.25;

  @media (--medium-plus) {
    grid-area: msg;
    margin: 0 var(--gutter);
  }
}

.product__msg-priceoff {
  padding: .5rem;
  border-radius: 4px;
  background: var(--cl_bg_pink_light);
  font-size: var(--font_size_-1);

  @media (--medium-plus) {
    margin-bottom: 1.5rem;
    padding: 1rem;
    font-size: var(--font_size_base);
  }

  a {
    margin-left: .5rem;
  }

  .priceoff__desc {
    font-size: var(--font_size_-1);
  }
}

.product__msg-main {
  @media (--medium-plus) {
    line-height: 1.4;
  }
}

.product__msg-ext {
  margin-top: .5rem;

  @media (--medium-plus) {
    margin-top: .75rem;
    padding-top: .75rem;
    border-top: 1px dashed var(--cl_gray_light);
  }
}
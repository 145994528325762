/*noinspection CssUnknownTarget*/
@define-mixin promo_images $p_name {

  @media only screen {
    background-image: url(/static/i/b/$(p_name)_bg.jpg);
    background-repeat: no-repeat;
  
    @media (-webkit-min-device-pixel-ratio: 1.3),
      (-o-min-device-pixel-ratio: 13/10),
      (min-resolution: 120dpi) {
        background-image: url(/static/i/b/$(p_name)_bg@2x.jpg);
    }
  }

  @media only screen and (min-width: 720px) {
    background-image: url(/static/i/b/$(p_name)_bg_m.jpg);

    @media (-webkit-min-device-pixel-ratio: 1.3),
      (-o-min-device-pixel-ratio: 13/10),
      (min-resolution: 120dpi) {
        background-image: url(/static/i/b/$(p_name)_bg_m@2x.jpg);
    }
  }

  @media only screen and (min-width: 1024px) {
    background-image: url(/static/i/b/$(p_name)_bg_l.jpg);

    @media (-webkit-min-device-pixel-ratio: 1.3),
      (-o-min-device-pixel-ratio: 13/10),
      (min-resolution: 120dpi) {
        background-image: url(/static/i/b/$(p_name)_bg_l@2x.jpg);
	  }
  }  
}

.l-promo {
  margin: 0 auto;
  max-width: var(--page_max_width);
  width: 100%;
  padding: var(--half-gutter);

  @media (--medium-plus) {
    margin: .5rem auto;
    padding: .5rem var(--gutter);
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    border: none !important;
  }  
}

.promo {
  /* padding: 8px 10px 10px; */
  text-align: center;
  line-height: 0;
}

.promo-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: white 50% 50% no-repeat;
}

.promo-block__link {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none !important;
}

.promo-svakom-erica {
  @mixin promo_images svakom-erica;

  display: block;
  aspect-ratio: 7.9;
  background-size: 100% auto;

  @media (--small) {
    aspect-ratio: 2.65;
  }

  .promo {
    padding: 0;
    background: #98b6dc;
  }
}

/*
.winter_vzhzhzhzhzh {
  @mixin promo_images winter_vzhzhzhzhzh;

  @media (--small) {
    flex-direction: column;
  }
}
*/

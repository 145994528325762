.product {
  @media (--medium-plus) {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto auto 1fr auto auto;
    grid-template-areas:
            "image header"
            "image msg"
            "image shop"
            "image properties"
            "image more"
            "image brand"
            "image ."
            "tags tags"
            "info info"
    ;
  }

  @media (--large) {
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-rows: auto auto auto auto 1fr auto auto;
    grid-template-areas:
            "image header shop"
            "image msg shop"
            "image properties shop"
            "image more shop"
            "image brand shop"
            "image tags shop"
            "info info shop"
    ;
  }
}

.product-archived {
  opacity: .5;
}

.product--resurrected {
  opacity: 1;
}

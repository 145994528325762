.l-footer {
  min-width: var(--page_min_width);
  min-height: 10rem;
  border-top: 4px solid var(--cl_gray_x-light);
  background: var(--cl_bg_gray_light);
}

.footer {
  height: 100%;

  max-width: var(--page_max_width);

  margin: 0 auto;

  padding: 1rem;

  @media (--medium-plus) {
    padding: 1rem var(--gutter);
  }

  a {
    color: var(--cl_gray);
  }
}

.footer__menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;

  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (--medium-plus) {
    margin: 1rem 0;
  }
}

.footer__menu-col {
  flex-basis: 100%;
  padding: 1rem;
  border-bottom: 1px solid var(--cl_gray_x-light);

  &:last-child {
    border-bottom: none;
  }

  @media (--medium-plus) {
    flex-basis: auto;
    padding: 0 1rem 0 0;
    border-bottom: none;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin: .75rem 0;
  }
}

.footer__menu-item-l2 {
  display: none;
  font-size: var(--font_size_-2);
  line-height: 1.36;

  @media (--medium-plus) {
    display: block;
  }

  &:before {
    content: '\2022';
    margin-right: 1rem;
    color: var(--cl_gray);
  }
}

.footer__menu-item-phone:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: .75rem;
  vertical-align: bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAlQTFRFMqhmM6hnAAAAdiLqnAAAAAN0Uk5T//8A18oNQQAAAH9JREFUeNqk00ESgCAMA8C0/3+0DoK00jQHOZrFYSCFh2VzxW840o9BGQcCli8Bmk8Bnj8CTT7EDcABBti8+gXS7RQiAzSA3Mfewy4sA2uA/QcmzsBOATsEEkNZo0DgvfAMTIKXhLfwoiXpuXVhZOV0aXXt9eDo0dPDS8f/EmAAn7MFLLRB6AUAAAAASUVORK5CYII=) no-repeat;
  background-size: 16px 16px;
}

.footer__menu-item-with-gap {
  @media (--medium-plus) {
    margin-top: 3rem !important;
  }
}

.footer__copy {
  margin: 3rem 0 4rem;
  padding-top: 1rem;
  border-top: 1px solid var(--cl_gray_x-light);
  text-align: center;
  line-height: 1.5;
  color: var(--cl_gray);

  @nest .footer.no-menu & {
    margin: 0;
    border-top: none;
  }

  a {
    color: var(--cl_gray);
  }
}

.orders {
  padding-top: 2rem;
  border-top: 10px solid var(--cl_border_gray_x-light);
}

.order {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 10px solid var(--cl_border_gray_x-light);

  font-size: var(--font_size_1);
  line-height: 1.5;

  &-cancelled {
    color: var(--cl_gray_x-light);
  }
}

.order__data {
  flex-basis: 30%;
  flex-grow: 1;
  margin-bottom: 1rem;
  padding: 0 1rem 1rem 0;
  border-right: 1px solid var(--cl_border_gray_x-light);
  border-bottom: 1px solid var(--cl_border_gray_x-light);
}

.order__number {
  display: inline-block;
  margin-right: .5rem;
  font-weight: bold;
}

.order__date {
  display: inline-block;

  @media (--medium-plus) {
    &:before {
      content: '\2022';
      margin-right: .75rem;
    }
  }
}

.order__amount {
  @nest .order-payable & {
    font-weight: bold;
    color: var(--cl_carmine);
  }
}

.order__methods {
  flex-basis: 70%;
  flex-grow: 1;
  margin-bottom: 1rem;
  padding: 0 0 1rem 1rem;
  border-bottom: 1px solid var(--cl_border_gray_x-light);
}

.order__shipping {
  display: inline-block;
}

.order__payment {
  display: inline-block;
}

.order__pay-btn {
  flex-basis: 100%;
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.order__pay-note {
  overflow: hidden;
  margin-bottom: 1rem;
  font-size: var(--font_size_-1);
  color: var(--cl_gray);
}

.order__status {
  flex-basis: 100%;
  flex-grow: 3;

  @nest .order-payable & {
    padding-top: 0;
    border-top: none;
  }
}

.order__pay-status {
  width: 100%;
  margin: 1rem 0;
}

.order__pay-message {
  display: inline-block;
  padding: .5rem 1rem;
  border-radius: 4px;

  &:before {
    content: unset;
    margin-right: 1rem;
  }

  @nest .order__pay-success & {
    color: var(--cl_text_success_green);
    background: var(--cl_bg_success_green);

    &:before {
      content: '\2713';
    }
  }

  @nest .order__pay-error & {
    color: var(--cl_text_error_red);
    background: var(--cl_bg_error_red);

    &:before {
      content: '\2715';
    }
  }
}

.order-list {
  table {
    width: 100%;
  }

  td {
    line-height: 1.4;
  }

  .o-cancelled {
    color: var(--cl_gray_x-light);
  }
}

.o-cancelled td {
  color: var(--cl_gray_x-light);
}

.o__date {
  white-space: nowrap;
  color: var(--cl_gray);
}

.o__total {
  text-align: right;
  white-space: nowrap;
}

.o__status {
}

.robokassa-form {
  .base-form__controls {
    margin: .5rem 0;
    text-align: left;
  }

  .base-form__field {
    padding: 0;
  }
}
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&subset=cyrillic');

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: color 200ms ease, border-color 200ms ease;

  &:link {
    color: var(--cl_link);
  }

  &:visited {
    color: var(--cl_link_visited);
  }

  &:hover {
    color: var(--cl_link_hover);
    border-color: currentColor;
  }

  &.no-border:hover {
    border-bottom-style: hidden;
  }

  &.perm {
    &:visited {
      color: var(--cl_carmine);
    }
    &:hover {
      color: var(--cl_carmine_dark);
    }
  }

  &.anchor {
    @mixin a-perm;

    border-bottom-style: dotted;
    border-bottom-color: currentColor;

    &:hover {
      border-bottom-style: solid;
    }
  }

  &.link-gray {
    color: var(--cl_gray);

    &:visited {
      color: var(--cl_gray);
    }

    &:hover {
      color: var(--cl_gray_dark);
    }
  }
}

dt {
  font-size: var(--font_size_5);
  font-weight: 500;
}

dd {
  margin-bottom: 3rem;
}

.hidden,
.--hidden {
  display: none !important;
}

.invisible {
  opacity: 0;
}

.hidden-small,
.hidden-sm {
  @media (--small) {
    display: none !important;
  }
}

.hidden-medium {
  @media (--large-below) {
    display: none !important;
  }
}

.medium-below {
  @media (--medium-plus) {
    display: none !important;
  }
}

.medium-plus {
  display: none;

  @media (--medium-plus) {
    display: initial;
  }
}

.nowrap {
  white-space: nowrap;
}

.centered {
  text-align: center;
}

.centered-fully {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;
  }
}

.indented-top {
  margin-top: 1.5rem;
}

.full-width {
  width: 100%;
}

.full-width-flex {
  flex-basis: 100% !important;
}

.full-bleed {
  overflow-x: scroll;

  @media (--medium-plus) {
    overflow-x: auto;
  }  
}

.no-border {
  border: none !important;
}

.no-border-bottom {
  border-bottom: none !important;
}

.no-border-top {
  border-top: none !important;
}

.red {
  color: var(--cl_text_error_red);
}

.expanded {
  letter-spacing: .75em;
  margin-right: .5rem;
}

.highlighted {
  @mixin highlighted;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: .5;
  z-index: 999;
  cursor: pointer;
}

.shade {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .5;
}

.fog,
.fog-white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 32px; /* высота .loader */
}

.fog-white {
  background: #fff;
  opacity: .6;
}

.icon {
  display: inline-block;
  background-repeat: no-repeat;
}

.unfold-icon {
  display: none;
  position: absolute;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 32px;
  height: 32px;
  background-image: svg-load('i/icon_unfold.svg');
  background-size: 32px 32px;
  transition: var(--transition_base);

  @nest .is-folded & {
    display: block;
  }
}

.marked-message {
  display: inline-block;
  padding: 0 .25rem;
  line-height: 1.2;
  background: var(--cl_bg_marked_red);
  color: white;
  font-size: var(--font_size_-3);
  text-transform: uppercase;
}

.marker {
  background: #ffe3e0;
  background: linear-gradient(180deg, rgba(255,255,255,0) 45%, #ffe3e0 55%);
  color: #000;
}

.footnote {
    margin-top: .5rem;
    font-size: var(--font_size_base);
    opacity: .75;

    &:before {
        content: '*';
        margin-right: .5rem;
    }
}

span.dfn {
  position: relative;
  cursor: pointer;
  border-bottom-style: dotted;
  border-bottom-width: 1px;
  margin-right: 18px;

  i {
    position: absolute;
    right: -16px;
    top: 4px;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    background: svg-load('i/icon_info.svg') no-repeat center;
  }
}

span.circle-spot {
    display: inline-block;
    width: 11px;
    height: 11px;
    vertical-align: middle;
    border-radius: 100%;
    background: black;
}

.collapsible {
  &:after {
    content: '▾';
    margin-left: .5rem;
  }
}

.collapsible.is-collapsed {
  &:after {
    content: '▸';
  }
}

.collapsible-content {
  padding: 1rem 1.5rem;
  background: var(--cl_bg_gray_light);
}

.thumb  a {
  display: block;
  border: 1px solid var(--cl_gray_x-light);
  border-radius: 4px;
  transition: border-color .5s ease;

  &:hover {
    border-color: var(--cl_gray_light);
  }
}

.thumb img {
  border-radius: 4px;
}

.loader {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  background-image: svg-load('i/loader_grid.svg' fill: var(--cl_gray_dark));
  background-size: 32px 32px;

  @nest .fog-white &, .fog & {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -16px 0 0 -16px;
  }
}

.discount_badge {
  position: absolute;
  top: .5rem;
  right: .5rem;
  width: 2.5rem;
  border-radius: 50%;
  font-size: var(--font_size_-4);
  line-height: 2.5rem;
  text-align: center;
  color: #fff;
  background: var(--cl_carmine);

  &.dis50 {
    background: #000000 !important;
  }

  &.dis40 {
    background: #e52d2d !important;
  }

  &.dis30 {
    background: #e94d4d !important;
  }

  &.dis20 {
    background: #ed6c6c !important;
  }

  &.dis15 {
    background: #ef7c7c !important;
  }

  &.dis10 {
    background: #f18c8c !important;
  }

  &.dis5 {
    background: #f5abab !important;
  }
}

.flatpage,
.text-block {
  @mixin text-block-base;
}

.text-block {
  ul li {
    margin: 1.5rem 0;
    padding-left: 1.5rem;
  }
}

.is-folded {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index:1;
    left: 0;
    top: 0;
    border-bottom: 1.5rem solid white;
    background: linear-gradient(to top,
      rgba(255,255,255, 1) 5%,
      rgba(255,255,255, 0) 40%
    );
    pointer-events: none;
  }

  .unfold-link {
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    font-size: var(--font_size_base);
    font-weight: bold;
    background: white;
  }
}

.text-cloud {
  position: relative;
  margin-top: 1.5rem;
  padding: .5rem 2rem;
  border-radius: 1.25rem;
  background: var(--cl_bg_gray_light);
  @mixin text-block-base;

  &:before {
    content: '';
    position: absolute;
    right: 3rem;
    top: -2rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2rem 2rem 0 0;
    border-color: transparent var(--cl_bg_gray_light) transparent transparent;
  }

  @media (--medium-plus) {
    margin-top: 0;

    &:before {
      content: '';
      position: absolute;
      left: -2rem;
      top: 2rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1.5rem 2rem 0 0;
      border-color: transparent var(--cl_bg_gray_light) transparent transparent;
    }
  }

  @media (--large) {
    &:before {
      left: -3rem;
      border-width: 1.5rem 3rem 0 0;
    }
  }

  .el--with-fog:before {
    border-bottom-color: var(--cl_bg_gray_light);
    background: linear-gradient(transparent 30px, var(--cl_bg_gray_light));
  }

  a {
    @mixin a-perm;
  }

  p {
    margin: 1rem 0;
  }
}

.text-cloud__hdr {
  margin: 1rem 0;
  font-weight: bold;
}

.text-cloud__content.is-folded {
  height: 200px;

  &:before {
    border-bottom-color: var(--cl_bg_gray_light);
    background: linear-gradient(to top,
      color-mod(var(--cl_bg_gray_light) a(100%)) 8%,
      color-mod(var(--cl_bg_gray_light) a(0%)) 30%
    );
  }
}

.frame-table {
  border-collapse: collapse;
  border: 1px solid var(--cl_gray_light);

  thead th, tr.frame-table__header {
    background: var(--cl_bg_gray_light);
  }

  th, td {
    padding: 1rem 1.25rem;
    border: 1px solid var(--cl_border_gray_x-light);
  }

  .td-title {
    font-weight: bold;
  }
}

table.table-centered {
  td, thead th {
    text-align: center;
  }
}

table.table-compact {
  @media (--small) {
    font-size: var(--font_size_-2);
    line-height: 1.3;
  }

  th, td {
    @media (--small) {
      padding: .75rem;
    }
  }
}

.priceoff__desc {
  color: var(--cl_text_sale_red);
}

.priceoff-badge,
.price-offer-badge,
.promo-badge {
  display: inline-block;
  margin-top: 2px;
  padding: 1px 3px;
  font-size: var(--font_size_-4);
  line-height: normal;
  vertical-align: top;
  text-transform: uppercase;
  background: var(--cl_text_error_red);
  color: white;
}

.promo-badge {
  background: #000;
  color: #ff0;

  a {
    display: block;
    width: 100%;
    height: 100%;
    color: #ff0;
    text-decoration: none;
    border: none;
  }
}

.price-offer-note {
  font-size: var(--font_size_-3);
  color: var(--cl_gray);
}

body {
  font-family: var(--font_family_base);
  font-size: var(--font_size_base);
  color: var(--cl_gray);
  background: white;
}

.layout-shade {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(190, 190, 190, .85);
  cursor: pointer;
}

h1 {
  margin: 1em 0 1.5em;
  padding: 1rem;
  text-align: center;
  font-size: var(--font_size_8);
  font-weight: 500;
  line-height: 1.2;
  color: #000;
  background: url(/static/i/section_hdr_bg.jpg) 50% 40% no-repeat;
  background-size: auto 45px;

  @media (--medium-plus) {
    font-size: var(--font_size_12);
  }  

  .selected {
    color: var(--cl_text_green);
  }

  .header-ext {
    display: block;
    margin-top: .2em;
    font-size: var(--font_size_4);
    font-weight: 300;
    color: var(--cl_gray_dark);
  }
}

h2 {
  margin: 1rem 0 2rem;
  padding: .5rem;
  text-align: center;
  font-size: var(--font_size_7);
  font-weight: normal;
  color: #000;
  background: url(/static/i/section_hdr_bg.jpg) 50% 40% no-repeat;
  background-size: auto 35px;
  filter: hue-rotate(120deg);
}

.grecaptcha-badge {
  display: none !important;
}

.smaller {
  font-size: var(--font_size_base);
  line-height: 1.4;
}

.product__gallery {
  position: relative;
  margin-top: 2rem;

  @media (--medium-plus) {
    grid-area: image;
    margin-top: 0;
  }
}

.product__images-slider {
    position: relative;
}

.product__images-viewport {
  overflow: hidden;
}

.product__images {
  display: flex;
  flex-wrap: nowrap;  
}

.product__image {
  width: 100%;
  flex: 0 0 100%;
  line-height: 0;

  @media (--medium-plus) {
    border: 1px solid var(--cl_gray_x-light);
    border-radius: 4px;

    @nest .swiper-container & {
      border: none;
    }
  }

  a {
    border-bottom: none;
    cursor: default;
  }

  img {
    width: 100%;

    @media (--medium-plus) {
      border-radius: 3px;
    }
  }
}

.slider__button-prev, .slider__button-next {
    position: absolute;
    top: 50%;
    border: none;
    width: 50px;
    height: 60px;
    margin-top: -30px;
    padding: 0;
    cursor: pointer;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23007aff' viewBox='0 0 512 512' xml:space='preserve'%3E%3Cpath d='M168.837 256 388.418 36.418c8.331-8.331 8.331-21.839 0-30.17s-21.839-8.331-30.17 0L123.582 240.915c-8.331 8.331-8.331 21.839 0 30.17l234.667 234.667c8.331 8.331 21.839 8.331 30.17 0s8.331-21.839 0-30.17z'/%3E%3C/svg%3E") 0 50% no-repeat;
    background-size: 100% auto;
    transition: var(--transition_base);    
}

.slider__button-next {
    right: 0;
    transform: rotate(180deg);
}

.product__image-caption {
  display: none;

  @media (--medium-plus) {
    display: block;
    margin-top: 4px;
    font-size: var(--font_size_-2);
    color: var(--cl_gray);
  }
}

.product__thumbs-viewport {
  overflow: hidden;

  .product__thumbs {
    flex-wrap: nowrap;
    margin-right: 0;
  }

  .product__thumb {
    flex: 0 0 22%;
    margin: 2% 0;
  }

  .product__thumb:not(.hidden) ~ .product__thumb:not(.hidden) {
    margin-left: 4%;
  }
}

.product__thumbs {
  margin-top: 1rem;
  margin-right: -4%;
  display: flex;
  flex-wrap: wrap;

  &.swiper-container-initialized {
    margin-right: 0;
  }
}

.product__thumb {
  margin: 2% 4% 2% 0;
  width: 21%;
  overflow-y: hidden;
  cursor: pointer;

  transition: max-height .25s;

  @media (--medium-plus) {
    margin: 6px 12px 6px 0;
    width: 82px;
    height: 108px;
  }

  @media (--large) {
    margin: 7px 14px 7px 0;
    width: 86px;
    height: 114px;
  }

  &.closed {
    max-height: 0;
  }

  a {
    width: 100%;
    height: auto;
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: top;

    @media (--medium-plus) {
      height: 106px;
    };

    @media (--large) {
      height: 112px;
    };
  }
}

.product__thumb--active a {
  border-color: var(--cl_gray_dark);
}

.product__thumbs-anchor {
  width: 100%;
  margin-top: 0.5rem;
  text-align: center;
}

.product__code {
  position: absolute;
  z-index: 2;
  top: 4px;
  left: 4px;
  display: inline-block;
  padding: 1px 3px;
  font-size: var(--font_size_-2);
  background: black;
  color: white;
  opacity: .5;
  white-space: nowrap;
  border-radius: 3px;
}

.product__discount_badge {
  z-index: 2;
  top: 1rem;
  right: 1rem;
  width: 3.5rem;
  font-size: var(--font_size_-2);
  line-height: 3.5rem;
}

.swiper-container-main {
  @media (--medium-plus) {
    border: 1px solid var(--cl_gray_x-light);
  }
}

.swiper-wrapper {
  box-sizing: border-box !important;
}

.swiper-button-prev,
.swiper-button-next {
  opacity: 0;
  transition: var(--transition_base);

  @media (--small) {
    display: none;
  };

  @nest .product__gallery:hover & {
    opacity: 1;
  }
}
.brands-list {
  margin-top: 2rem;
  padding: 0 1rem;

  @media (--medium-plus) {
    column-count: 3;
  }
}

.brand-item {
  list-style-type: none;
  margin-bottom: 2rem;
  display: flex;

  @media (--medium-plus) {
    break-inside: avoid;
  }

  &--selected {
    font-weight: bold;
  }

  &--not-available {
    color: var(--cl_gray_x-light);
    a {
      color: var(--cl_gray_x-light);
    }
  }
}

.brand-name {
  margin-left: .75rem;
  font-size: var(--font_size_3);

  @nest .brand-item--popular & {
    padding-left: .75rem;
    border-left: 4px solid var(--cl_tag_popular);
  }
}

.brand-num_products {
  margin-left: .5rem;
  vertical-align: super;
  font-size: var(--font_size_-3);
  color: var(--cl_gray);
}

.brand-info {
  display: block;
  margin-top: .5rem;
  font-size: var(--font_size_-3);
  color: var(--cl_gray_x-light);
}

.brand-country {
  margin-right: .25rem;
  text-transform: uppercase;
}

.brand-oneliner {
  &:before {
    content: "\2022";
    margin-right: .4rem;
  }
}

.tags-list {
  margin-top: -.5rem;
  padding: 1rem 1rem .25rem;
  border-radius: 6px;
  background: var(--cl_bg_gray_light);

  @media (--medium-plus) {
    margin-top: 0;
  }

  &--activated {
    .tag:not(.tag--activated) {
      opacity: .3;
    }
  }
}

.tags-group__hdr {
  display: block;
  margin: 0 1rem .75rem 0;
  font-size: var(--font_size_-1);
  line-height: 1.5;
  color: var(--cl_gray);

  @media (--medium-plus) {
    display: inline-block;
    margin: 0 1.5rem;
    text-align: right;
  }
}

.tagspots {
  min-width: 53px;
}

.tagspot {
  display: inline-block;
  margin: .4rem .75rem 0 0;
  width: 10px;
  height: 10px;
  border-radius: 10px;

  &--empty {
    border: 1px dotted var(--cl_gray_x-light);
  }
}

/*noinspection ALL*/
@each $tag in (popular, exclusive, premium, price-quality) {
  .tag-$(tag) {
    a.cnt {
      border-color: transparent;
      background-color: var(--cl_tag_$(tag));
      color: white;

      &:hover {
        border-color: transparent;
        background-color: color-mod(var(--cl_tag_$(tag)) shade(10%));
        color: white;
      }
    }
  }

  .tagspot-$(tag) {
    background-color: var(--cl_tag_$(tag));
  }
}

.tag-popular {
  margin-right: 1.5rem;
  padding-right: 1.5rem;
}

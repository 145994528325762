.shipping-city-name {
  white-space: nowrap;
  margin-left: .25rem;
  font-weight: bold;
}

.shipping-city-select {
  margin: 1.5rem 0;

  @media (--medium-plus) {
    margin: 2.5rem 0;
  }

  select {
    min-width: 280px;
  }
}

.no-city-link {
  margin: 1.5rem 0;
  text-align: center;
  font-size: var(--font_size_-1);
}
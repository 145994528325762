.l-main {
  padding: 1rem;
  min-width: var(--page_min_width);

  @media (--medium-plus) {
    margin: 0 auto;
    max-width: var(--page_max_width);
    padding: 1rem var(--gutter) 3rem;
  }

  &.simple,
  &.no-breadcrumbs {
    @media (--medium-plus) {
      padding: 2rem var(--gutter);
    }
  }

  &.with-messages {
    @media (--medium-plus) {
      padding-top: 1rem;
    }
  }
}

.l-special-message:not(.hidden) ~ .l-main {
  @media (--medium-plus) {
    padding-top: 0;
  }
}

.l-archived-message, .l-special-message {
  overflow: hidden;
  min-width: var(--page_min_width);

  @media (--medium-plus) {
    margin: 0 auto;
    max-width: var(--page_max_width);
    padding: 1rem var(--gutter);
  }
}

.archived-message, .special-message {
  text-align: center;
  background: var(--cl_bg_pink_light);
  padding: 2rem 1rem 1rem;

  .hdr {
    font-size: var(--font_size_6);
  }

  .replacement-product {
    font-size: var(--font_size_3);
    line-height: 1.3;

    a {
      margin-left: .5rem;
      font-weight: 500;
    }
  }

  .note {
    margin-top: 3rem;
    font-size: var(--font_size_-1);
  }
}

.archived-message {
  color: var(----cl_gray_dark);
  background: #e8f9fd;

  @media (--medium-plus) {
    border-radius: 4px;
  }
}

.special-message {
  padding: 2rem 1rem;
  border: 4px solid var(--cl_carmine);

  .hdr {
    color: var(--cl_text_sale_red);
  }
}

.l-special-banner {
  margin: 0 auto;
  max-width: var(--page_max_width);
  width: 100%;

  @media (--medium-plus) {
    margin: 1rem auto .5rem;
    padding: .5rem var(--gutter);
  }
}

.l-special-message:not(.hidden) + .l-special-banner {
  @media (--medium-plus) {
    margin-top: 0;
    padding-top: 0;
  }
}

.special-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: .25rem;
  padding: .5rem .5rem .7rem;
  text-align: center;
  font-size: var(--font_size_-3);
  text-transform: uppercase;
  color: white;
  background: #000;

  @media (--medium-plus) {
    flex-direction: row;
    padding: 1rem;
    border-radius: 4px;
    font-size: var(--font_size_-1);
  }

  strong {
    margin-right: 1rem;
    font-size: var(--font_size_5);
    color: yellow;
  }
}

.special-banner-note {
  margin-top: .5rem;
  text-align: center;
  font-size: var(--font_size_-2);
  color: var(--cl_gray_light);
}

.vacation-message {
  background-color: #f9ea8f;
  background-image: linear-gradient(315deg, #f9ea8f 0%, #aff1da 74%);
  border: none;
  color: black;

  @media (--medium-plus) {
    border-radius: 4px;
  }

  .hdr {
    color: #0d402e;
  }
}

.pause-message {
  background: #ffdee9;
/*  background: -webkit-linear-gradient(to right, #faffd1, #a1ffce); */
  background: -webkit-linear-gradient(345deg, #ffdee9 0%, #b5fffc 100%);
  background: linear-gradient(345deg, #ffdee9 0%, #b5fffc 100%);

  border: none;
  color: black;

  @media (--medium-plus) {
    border-radius: 4px;
  }

  .hdr {
    color: #0d402e;
  }
}

.special-message-close {
  font-size: var(--font_size_-1);
}
.pages {
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: var(--font_size_3);
  font-weight: 500;

  @media (--medium-plus) {
    justify-content: flex-start;
  }

  li {
    list-style-type: none;
  }
}

.page, .ellipsis {
  margin: 1rem 0;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;

  @media (--medium-plus) {
    margin: 1rem .5rem;
  }
}

.page {
  border: 1px solid transparent;
  border-radius: 4px;

  &:hover {
    border-color: var(--cl_bg_pink_x-light);
    background: var(--cl_bg_pink_x-light);
  }

  &.page--current {
    margin: 1rem;
    border-color: var(--cl_carmine);
    background: var(--cl_carmine);
    color: white;
  }

  &--disabled,
  &--disabled:hover {
    color: var(--cl_gray_x-light);
    border-color: var(--cl_gray_xx-light);
    cursor: not-allowed;
    background: none;
  }
}

.page__link,
.page__button {
  display: block;
  height: 100%;
  line-height: 3.5rem;
  border: none;
}

.page__button {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.page__button-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.page__button-label {
  position: absolute;
  left: -9999px;
}

.page-next .page__link,
.page-previous .page__link {
  &:hover {
    background: inherit;
    border-bottom-color: currentColor;
  }

  span {
    display: none;
  }
}

.page-previous,
.page-next {
  border-color: var(--cl_gray_xx-light);
}

.page-previous {
  margin-left: 0;
  margin-right: 1rem;
}

.page-next {
  margin-left: 1rem;
}

.ellipsis {
  color: var(--cl_gray);
}

.cart-content {
  margin-top: 1rem;
}

.cart-items {
  margin: 0;
  padding-left: 0;
}

.cart-item-before {
  display: inline-block;
  margin-top: 1rem;
  padding: 2px 3px;
  font-size: var(--font_size_-1);
  color: var(--cl_gray_dark);
  background: var(--cl_bg_pink_light);
}

.cart-item {
  position: relative;
  overflow: hidden;

  padding: 1.5rem 0;

  display: grid;
  grid-template-areas:
    "image product product product"
    "image price price price"
    "image quantity total total"
  ;

  &.out-of-stock {
    grid-template-areas:
      "image product product product"
    ;
    opacity: .4;
    transition: var(--transition_base);

    &:hover {
      opacity: 1;
    }
  }

  grid-template-columns: 1fr 5fr 5fr 5fr;

  @media (--medium-plus) {
    grid-template-areas:
      "image product product product product product"
      "image . . . . ."
      "image quantity price voucher total total"
    ;
    grid-template-columns: 1fr 3fr 6fr 2fr 1fr 2fr;
  }

  width: 100%;
  border-bottom: 1px solid var(--cl_border_gray_x-light);

  &--last {
    border-bottom: none;
  }

  .product-availability {
    margin: 1rem 0;
  }
}

/* cart-item__thumb */
.cart-item__thumb {
  grid-area: image;
  position: relative;
  padding-right: 1rem;

  @media (--medium-plus) {
    padding-right: 1.5rem;
  }

  img {
    margin: .5rem;
    vertical-align: top;
  }
}

.out-of-stock .cart-item__thumb a {
  transition: opacity 400ms ease;

  &:hover {
    opacity: 1;
  }
}
/* /cart-item__thumb */

/* cart-item__info */
.cart-item__info {
  grid-area: product;
  padding: .25rem 2.5rem .5rem 0;
}

.cart-item__name {
  font-size: var(--font_size_2);

  @media (--small) {
    white-space: nowrap;
    overflow: hidden;
  }
}

.out-of-stock .cart-item__name a {
  color: var(--cl_gray);
}

.cart-item__variant {
  margin-top: 0.25rem;
  font-size: var(--font_size_-1);
  color: var(--cl_gray);
}
/* /cart-item__info */

/* cart-item__quantity */
.cart-item__quantity {
  grid-area: quantity;
  align-self: end;

  @nest .out-of-stock & {
    align-self: start;
  }

  @media (--medium-plus) {
    padding-right: 1.5rem;
  }

  font-size: var(--font_size_-1);
  color: var(--cl_gray);
}

.form-quantity {
  display: flex;

  .q-upd {
    order: 3;
  }
}

.cart-item__quantity-value {
  width: 4rem;
  padding: .5rem 1rem;
  text-align: center;
  border: 1px solid var(--cl_gray_x-light);
  font-size: var(--font_size_1);

  &.more-than-one {
    border-width: 2px;
    border-color: var(--cl_border_green);
  }
}

.cart-item__quantity-change {
  padding: 0 8px;
  cursor: pointer;
  font-size: var(--font_size_6);
  border: none;
  color: var(--cl_gray_x-light);
  transition: color .5s ease, opacity .5s ease;

  &.q-dec,
  &.q-upd {
    padding-left: 0;
  }

  &.q-upd {
    display: none;

    @media (--medium-plus) {
      display: block;
      padding-right: 0;
    }
  }

  &:hover {
    color: var(--cl_carmine) !important;
  }
}

.out-of-stock .cart-item__quantity {
  font-size: var(--font_size_1);
  line-height: 2.5rem;
}
/* /cart-item__quantity */

/* cart-item__price */
.cart-item__price {
  grid-area: price;
  line-height: 1.75rem;
  color: var(--cl_gray);

  @media (--small) {
    font-size: var(--font_size_-1);
  }

  @media (--medium-plus) {
    align-self: center;
    white-space: nowrap;
    text-align: center;
  }

  @nest .cart-item--more-than-one & {
    padding-bottom: 0;
  }
  
  @nest .priceoff & {
    @media (--small) {
      text-align: left;

      .undiscounted-price,
      .sale-price {
        display: none;
      }
    }
  }
  
  .product-price__currency {
    font-size: var(--font_size_-2);
  }

  .undiscounted-price {
    display: inline-block;
    margin: 0;
    text-decoration: line-through;
    font-size: inherit;

    &:after {
      content: none;
    }
  }

  .discount-value {
    display: inline-block;
    margin: 0 .25rem;
    font-size: var(--font_size_-2);
  }

  .sale-price {
    display: inline;
    color: var(--cl_text_sale_red);

    &:before {
      content: '\2192';
      margin-right: .25rem;
    }
  }

  .price-offer-badge,
  .priceoff-badge {
    margin-right: .5rem;
  }

  .price-offer-note {
    display: inline-block;

    @media (--medium-plus) {
      display: block;
      margin-bottom: -.5rem;
    }
  }
}

.cart-item__price--offered {
  text-align: left;

  @media (--medium-plus) {
    padding-bottom: 0;
    text-align: center;
  }

  .undiscounted-price,
  .sale-price {
    @media (--small) {
      display: none;
    }
  }
}
/* /cart-item__price */

/* cart-item__voucher */
.cart-item__voucher {
  grid-area: voucher;

  line-height: 2.5rem;
}
/* /cart-item__voucher */

/* cart-item__discount */
.cart-item__discount {
  grid-area: discount;
  align-self: end;
  text-align: right;

  color: var(--cl_text_sale_red);

  @media (--medium-plus) {
    align-self: center;
    text-align: center;
  }

}
/* /cart-item__discount */

/* cart-item__line-total */
.cart-item__line-total {
  grid-area: total;
  align-self: end;
  margin-bottom: .5rem;
  white-space: nowrap;
  line-height: 1.75rem;
  text-align: right;

  @media (--medium-plus) {
    align-self: center;
    margin-bottom: 0;
    min-width: 10rem;
  }

  @nest .cart-item--more-than-one & {
    margin-bottom: 0;
  }

  .price.is-updating {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -20px;
      width: 16px;
      height: 16px;
      background-image: svg-load('i/ic-reload.svg');
    }
  }

  .price-amount {
    font-size: var(--font_size_2);
  }
}

.cart-item__pcs-price {
  display: block;
  margin-bottom: -.5rem;
  font-size: var(--font_size_-3);
  color: var(--cl_gray_x-light);
}
/* /cart-item__line-total */

/* cart-item__remove */

.cart-item__remove {
  position: absolute;
  top: 1.5rem;
  right: 0;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  /* background: svg-load('i/ic_cancel_black_24px.svg') no-repeat center; */
  background: svg-load('i/icon_delete.svg') no-repeat center;
  border: none;
  opacity: .25;
  transition: opacity .75s ease;
  text-indent: 999px;
}

.cart-item:hover .cart-item__remove {
  opacity: .5;

  &:hover {
    opacity: 1;
  }
}
/* /cart-item__remove */

.cart-item-removed-msg {
  padding: 1rem;
  border-bottom: 1px solid var(--cl_border_gray_x-light);
  background: var(--cl_bg_gray_light);
}

.cart-item__priceoff-desc,
.cart-item__discount-desc {
  margin-top: 1rem;
  display: inline-block;
  padding: 2px 4px 3px;
  border-radius: 2px;
  font-size: var(--font_size_-1);

  @media (--small) {
    margin-top: .25rem;
    padding: 0;
    background: none;
    .content {
      display: none;
    }
  }  

  .content {
    color: var(--cl_gray_dark);
  }

  .priceoff-badge {
    margin: 1px 2px 0 0;
    vertical-align: text-top;
    border-radius: 2px;
  }
}

.cart-item__priceoff-desc {
  background: var(--cl_bg_pink_light);
}
.about__email {
  margin-left: .5rem;
  font-size: var(--font_size_2);
}

.about__phone {
  white-space: nowrap;
  margin-left: .5rem;
  font-size: var(--font_size_2);
  font-weight: bold;
  letter-spacing: 1px;
}

.about__map {
  margin: 1.5rem 0;
  height: 502px;
  border: 1px solid var(--cl_gray_x-light);
}

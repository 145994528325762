.cart-upsell {
  margin-top: .5rem;
  border-top: 1px dotted var(--cl_gray_x-light);
  text-align: center;
  font-size: var(--font_size_1);
  line-height: 1.25;
}

.cart-upsell__msg {
  display: inline-block;
  padding: 1rem 24px;

  @media (--medium-plus) {
    background: svg-load('i/icon_plus_in_circle.svg') 0 11px no-repeat;
  }
}

.cart-upsell__note {
  font-size: var(--font_size_-1);
  color: var(--cl_gray_light);
}
.category__description {

    /* отключаем пэдингом схлопывание отступов и сдвигаем на этот отступ вверх */
    margin: -1em 0 1rem;
    padding: .01em 0;
  
    @mixin text-block-base;
  
    @nest .no-header & {
        margin-top: 0;
    }
  
    &.is-folded {
        margin-bottom: 2rem;
    }
}  

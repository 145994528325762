.account {
  @media (--medium-plus) {
    display: flex;
    min-height: 100vh;
    flex-direction: row;
    flex: 1;

    h1 {
      display: none;
    }
  }
}

.account__menu {
  padding: 0;
  list-style-type: none;
  font-size: var(--font_size_2);

  @media (--medium-plus) {
    flex: 0 0 20%;
    padding-right: 1.5rem;
    border-right: 1px dotted var(--cl_gray_light);
  }

  li {
    margin: 1rem 0;

    @media (--medium-plus) {
      margin: 0;
      padding: 1.5rem;
      border-bottom: 1px solid var(--cl_border_gray_x-light);
    }
  }

  a {
    @mixin a-perm;
  }
}

.account__content {
  font-size: var(--font_size_base);
  @media (--medium-plus) {
    flex: 0 0 80%;
    padding-left: var(--gutter);
    padding-top: 1.5rem;
  }
}

.fs-user-credentials {
  display: inline-block;
  text-align: left;

  @media (--medium-plus) {
    display: block;
  }
}

.account section {
  margin-bottom: 3rem;
}

/*
.robokassa-form {
  margin-top: 2.5rem;
}
*/

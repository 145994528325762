.product__tags {
  margin-top: 1rem;
  padding: 0;

  @media (--medium-plus) {
    grid-area: tags;
    margin: 2rem 0 -1rem;
  }

  @media (--large) {
    align-self: start;
    justify-self: center;
    margin: var(--gutter);
  }
}

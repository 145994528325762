.l-messages {
  margin: 0 auto;
  max-width: var(--page_max_width);
  width: 100%;

  @media (--medium-plus) {
    margin: 1.5rem auto;
    padding: .5rem var(--gutter);
  }
}

.messages {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  font-size: var(--font_size_-2);
  line-height: 1.5;

  @media (--medium-plus) {
    padding: 0;
    font-size: var(--font_size_base);
  }

  li {
    padding: 0.75rem 1rem;
    text-align: center;
    background: var(--cl_bg_gray_light);

    &.success {
      color: var(--cl_text_success_green);
      background: var(--cl_bg_success_green);

      a {
        @mixin a-perm;
        border-bottom-color: currentColor;
      }
    }

    &.error {
      color: var(--cl_text_error_red);
      background: var(--cl_bg_error_red);
    }
  }
}

.product__comments {
  padding-top: 1.5rem;

  h2 {
    margin-top: .5rem;
  }
}

.comments__product {
  margin-top: 3rem;
  color: var(--cl_gray);

  @nest .no-comments & {
    margin-top: 1rem;
  }
}

ol.comments {
  list-style-type: none;
  margin: 0;
  padding: 1rem 0;
}

.comment {
  padding: 1rem 0;
  border-top: 3px solid var(--cl_carmine);

  @media (--medium-plus) {
    padding: 2rem 0;
  }
}

.comment__meta {
  margin-top: 1rem;
  font-size: var(--font_size_-1);
  color: var(--cl_gray);
}

.comment__date {
  margin-right: .5rem;
}

.comment__name {
  margin-left: .5rem;
  font-weight: bold;
}

.comment__body {
  line-height: 1.5;
}

.child-comment {
  border-top: 3px double var(--cl_border_gray_x-light);
  padding-left: 2rem;

  @media (--medium-plus) {
    padding-left: 6rem;
  }

}

.child-comment-last {
}

.parent-comment {
}

.comment-form-anchor {
  @media (--medium-plus) {
    margin-left: -1.25rem;
  }
}

.comments__link {
  margin-bottom: 1.5rem;
  padding: 2rem 0;
  border-top: 1px dashed var(--cl_gray_x-light);
  text-align: center;
}

.comment-form {
  margin-top: 2rem;

  .base-form__label {
    @media (--medium-plus) {
      flex: 0 0 30%;
    }
  }

  .base-form__field {
    @media (--medium-plus) {
      flex: 0 0 70%;
    }
  }

  .base-form__help-text {
    @media (--medium-plus) {
      padding-left: calc(30% + 1rem);
    }
  }

  .base-form__controls {
    @media (--medium-plus) {
      padding-left: 30%;
    }
  }

  textarea[name=comment] {
    width: 100%;
  }
}
.product__brand-collection {
  display: none;

  @media (--medium-plus) {
    display: block;
    grid-area: brand;
    margin: 0 var(--gutter);
    padding: 0 1.5rem 0 0;
    color: var(--cl_gray);
  }

  a {
    margin-left: 4px;
  }
}

.product__brand,
.product__collection {
  margin: 1rem 0;
}

.product__brand-logo {
  @media (--medium-plus) {
    margin-right: var(--gutter);
  }

  /*
  a {
    display: inline-block;
    padding: 4px;
    border: 1px solid transparent;
  }

  a:hover {
    border-color: var(--cl_gray_x-light);
  }

  */
  img {
    display: block;
    max-width: 90px;
  }
}
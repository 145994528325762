.cart-summary {
  font-size: var(--font_size_2);
}

.cart-content {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1rem;
  }
}

.product__header {
  h1 {
    text-align: left;
    padding: .5em 0;
    background-position: 0 0;
  }

  @media (--medium-plus) {
    grid-area: header;
    position: relative;
    margin-bottom: 1.5rem;
    padding: 0 var(--gutter);

    h1 {
      padding-top: 0;
      background-size: auto 35px;
    }

    .header-ext {
      display: inline;
      font-size: var(--font_size_3);
    }
  }

  .promo-badge {
    margin-top: .75rem;
    border-radius: 3px;
    font-weight: 500;
    font-size: var(--font_size_-3);
    letter-spacing: 1px;

    a {
      padding: 3px 6px;
    }
  }
}

.product__title {
  margin: 0.5rem 0;
  font-size: var(--font_size_10);
  font-weight: normal;

  .priceoff-badge {
    display: inline-block;
    margin-left: .5rem;
    vertical-align: top;
  }
}

.product__title-ext {
  font-size: var(--font_size_1);
  color: var(--cl_gray);
}

.product__summary {
  font-size: var(--font_size_1);
  line-height: 1.4;
  color: var(--cl_gray);

  /*
  @media (--medium-plus) {
    font-size: var(--font_size_-2);
    line-height: normal;
    text-transform: uppercase;
  }
  */
}

.cart-totals {
  padding: 1rem 0;
  border-top: 4px solid var(--cl_border_gray_x-light);
  text-align: center;

  @media (--medium-plus) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;
  }

  .price {
    margin-left: 1rem;
  }
}

.cart-totals__voucher {
  margin: 1.5rem 0;

  @media (--medium-plus) {
    margin-top: .5rem;
  }
}

.cart-totals__voucher--applied {
  display: flex;
  justify-content: space-between;
  margin-top: 0;

  @media (--medium-plus) {
    width: 100%;
    flex-basis: 100%;
  }
}

.cart-totals__discount {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  text-align: left;

  @media (--medium-plus) {
    width: 100%;
    flex-basis: 100%;
  }
}

.cart-totals__discount-desc {
  width: 75%;

  @media (--medium-plus) {
    width: 40%;
  }
}

.cart-totals__discount-name {
  font-size: var(--font_size_2);
  font-weight: bold;
  color: var(--cl_text_green);
}

.cart-totals__discount-note {
  flex-basis: 100%;
  margin-top: .5rem;
  font-size: var(--font_size_-2);
  line-height: 1.25;
  color: var(--cl_gray_light);
}

.cart-totals__voucher-code {
  .code {
    margin-right: .5rem;
    font-size: var(--font_size_2);
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--cl_text_green);

    @media (--medium-plus) {
      margin-left: .5rem;
    }
  }
}

.voucher-remove-link {
  text-transform: uppercase;
  font-size: var(--font_size_-3);
}

.cart-totals__voucher-discount,
.cart-totals__discount-value {
  color: var(--cl_text_green);
  font-weight: bold;

  .price-amount {
    font-size: var(--font_size_2);
  }
}

.cart-totals__voucher-form {
  margin: 2rem 0 1rem;
  line-height: var(--font_size_5);
  color: var(--cl_gray);

  @media (--medium-plus) {
    margin: 0;
    text-align: inherit;
  }

  .voucher-input {
    margin-right: 1rem;
    &::placeholder {
      color: var(--cl_gray);
    }
  }

  .btn {
    margin-left: -1px;
  }
}

.cart-totals__amount {
  .price-amount {
    font-size: var(--font_size_6);
  }

  @media (--medium-plus) {
    text-align: right;
  }
}

.cart-actions {
  margin-top: .5rem;
  text-align: center;

  @media (--medium-plus) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.cart-totals__checkout {
  font-size: var(--font_size_1);
}

.cart-clear-link {
  margin-top: 2.5rem;
  text-align: right;

  @media (--medium-plus) {
    align-self: center;
    margin-top: 0;
    text-align: left;
  }
}

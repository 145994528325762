input,
select,
textarea {
  display: inline-block;
  margin: 0;
  padding: .6rem;
  border: 1px solid var(--cl_gray_x-light);
  border-radius: 3px;
  color: var(--cl_gray);
  background: #fff;

  @media (--medium-plus) {
    padding: .8rem;
  }

  &.error {
    background: var(--cl_bg_pink_light);
    border-color: var(--cl_text_error_red);
  }
}

select {
  padding: 7px 8px;
}

a.btn {
  display: inline-block;
  padding-top: .6rem;
  padding-bottom: .6rem;

  @media (--medium-plus) {
    padding-top: .8rem;
    padding-bottom: .8rem;
  }
}

.btn {
  white-space: nowrap;
  vertical-align: top;
  padding: .7rem 3rem;
  cursor: pointer;
  font-family: var(--font_family_base);
  color: var(--cl_gray);
  background: inherit;
  border: 2px solid var(--cl_gray_light);
  border-radius: 4px;
  transition: border-color .3s, color .3s;

  &:hover {
    border-color: var(--cl_carmine);
    color: var(--cl_carmine);
  }

  &-secondary {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    border: 1px solid var(--cl_gray_x-light);
    color: var(--cl_gray);
  }

  &-notable {
    border-color: var(--cl_carmine);
    color: var(--cl_carmine);
    font-weight: 500;

    &:visited {
      color: var(--cl_carmine);
    }

    &:hover,
    &:focus {
      border-color: black;
      color: black;
    }
  }

  &--blocked,
  &[disabled] {
    border-color: var(--cl_gray_light) !important;
    color: var(--cl_gray_light) !important;
    background-color: var(--cl_bg_gray_light) !important;
  }

  &[disabled] {
    opacity: .5;
  }

  &--frozen {
    pointer-events: none;
  }

  @nest .is-disabled & {
    pointer-events: none;
  }
}

.btn--small {
  padding: 4px 7px 6px;
  line-height: 16px;
}

.btn--large {
  padding: 6px 14px 8px;
  line-height: 22px;
  font-size: var(--font_size_1);
}

.tingle-form {
  text-align: center;
}

.form-errors {
  margin-top: .25rem;
  font-size: var(--font_size_-2);
  color: var(--cl_text_error_red);
}

/* base-form */

.base-form {
  border-top: 1px dashed var(--cl_gray_x-light);
  font-size: var(--font_size_1);
  text-align: center;

  @media (--medium-plus) {
    padding: 2rem;
    border: 2px solid var(--cl_gray_xx-light);
    border-radius: 6px;
  }

  &.form-hdrless {
    padding-top: 2rem;
    @media (--medium-plus) {
      padding-top: 2.5rem;
    }
  }

  &.is-disabled {
    opacity: .3;
  }

  fieldset {
    display: inline-block;
    padding: 0;
    border: none;
    text-align: left;

    @media (--medium-plus) {
      display: block;
    }

  }

  &.form-centered fieldset {
    text-align: center;
  }

  legend {
    margin: 2rem 0;
    text-align: center;
  }

  /*
  .textinput {
    line-height: 1.5;
  }
  */

  .checkboxinput {
    margin: -2px .5rem 0 0;
    vertical-align: middle;
  }

  .asterisk {
    margin-left: .25rem;
  }

}

.base-form--with-errors {
  @media (--medium-plus) {
    border: 3px solid var(--cl_border_error_red);
  }
}

.base-form__hdr {
  margin: 1rem 0 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px dashed var(--cl_gray_x-light);
  text-align: center;
  font-size: var(--font_size_6);
  line-height: 1.4;

  @nest .shipping-city-form & {
    margin: 2rem 0 1rem;
  }

  @media (--medium-plus) {
    margin-bottom: 2rem;
  }

  &--no-border {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.base-form__sub-hdr {
  margin: 1rem 0 2rem;
  font-size: var(--font_size_3);
  line-height: 1.3;
  color: var(--cl_text_green);
}

.base-form__line {
  margin-bottom: 1rem;
  text-align: left;

  @media (--medium-plus) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &.full-width {
      display: block;
      text-align: center;
    }
  }

  @nest .form-centered & {
    text-align: center;
  }
}

.base-form__block {
  @media (--medium-plus) {
    @nest .form-aligned & {
      padding-left: calc(45% + 1rem);
      text-align: left;
    }
  }
}

.base-form__help-text {
  margin-top: .5rem;
  font-size: var(--font_size_-1);
  color: var(--cl_gray);

  @media (--medium-plus) {
    @nest .form-aligned & {
      width: 100%;
      padding-left: calc(45% + 1rem);
      text-align: left;
    }
  }
}

.base-form__radio {
  text-align: left;

  @media (--medium-plus) {
    display: block;
  }

  ul {
    margin: 0;
    padding-left: 3rem;
    list-style: none;

    @media (--medium-plus) {
      margin: 2.5rem auto;
      padding-left: 4rem;
      max-width: 600px;
    }
  }

  li {
    margin-bottom: 2rem;
  }

  label {
    position: relative;
    vertical-align: top;
    line-height: normal;
  }

  input[type=radio] {
    position: absolute;
    top: 2px;
    left: 0;
    margin-left: -2.5rem;
    padding: 0;
    width: auto;
    vertical-align: top;

    @media (--medium-plus) {
      margin-left: -3rem;
    }

  }
}

.radio_note {
  margin-top: .5rem;
  font-size: var(--font_size_-1);
  line-height: 1.3;
  color: var(--cl_gray);

  @media (--medium-plus) {
    line-height: 1.5;
  }
}

.radio--disabled .radio_note {
  color: var(--cl_text_disabled);
}

.base-form__controls {
  margin-top: 2rem;
  text-align: center;

  @media (--medium-plus) {
    display: block;

    @nest .form-aligned & {
      padding-left: 45%;
      text-align: left;
    }
  }
}

.base-form__label {
  display: inline-block;
  margin: .5rem 0;

  @media (--medium-plus) {
    flex: 0 0 45%;
    margin: 0;
    padding: .8rem;
    text-align: right;
    line-height: 1.5;

    @nest .form-checkbox & {
      flex: 0 0 100%;
      padding-left: calc(40% + 1rem);
      text-align: left;
    }
  }
}

.base-form__field {
  @media (--medium-plus) {
    flex: 0 0 55%;
    padding-left: 1rem;
    max-width: none;

    @nest .form-aligned & {
      text-align: left;
    }

    @nest .full-width &,
    .form-centered & {
      padding-left: 0;
    }
  }
}

.base-form__non-field-errors {
  margin-top: .5rem;
  color: var(--cl_text_error_red);
  list-style-type: none;
  padding: 0;
}

.base-form__field-errors {
  margin-top: .5rem;
  font-size: var(--font_size_-1);
  color: var(--cl_text_error_red);

  @media (--medium-plus) {
    @nest .form-aligned & {
      width: 100%;
      padding-left: 45%;
    }
  }

  .invalid-feedback {
    display: block;
    line-height: 1.5;

    @media (--medium-plus) {
      margin: 0 1rem 1rem;
    }

    a {
      border-bottom-color: currentColor;
    }
  }
}

.base-form__hpt {
  display: none;
}
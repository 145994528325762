.tags-group {
  margin: .5rem 0;
  padding: 0;
}

.tag {
  display: inline-block;
  margin: 0 1rem 1rem 0;

  .cnt {
    display: block;
    padding: .4rem .75rem .65rem;
    border: 1px solid var(--cl_gray_x-light);
    border-radius: 3px;
    font-size: var(--font_size_-1);
    color: var(--cl_gray);
    background: white;
  }

  a.cnt:hover {
    border-color: var(--cl_gray_light);
    color: var(--cl_gray_dark);
    background: var(--cl_bg_gray_light);
  }

  small {
    margin-left: .25rem;
    vertical-align: super;
    color: var(--cl_gray);
  }
}

.tag-auto {
  .cnt {
    border-style: dashed;
  }

  .cnt:before {
    content: '✓';
    margin-right: .5rem;
    font-weight: bold;
  }
}

.tag-active {
  .cnt {
    color: white;
    border-color: var(--cl_carmine) !important;
    background: var(--cl_carmine);
  }

  .cnt:before {
    content: '×';
    margin-right: .5rem;
    font-weight: bold;
  }

  a.cnt:hover {
    color: white;
    border-color: var(--cl_carmine_dark) !important;
    background: var(--cl_carmine_dark);
    text-decoration: line-through;
  }

  small {
    color: white;
  }
}

.tag-disabled {
  .cnt {
    color: var(--cl_gray_x-light);
    border-color: var(--cl_border_gray_x-light) !important;
  }

  small {
    color: var(--cl_gray_x-light);
  }
}
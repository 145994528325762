.l-main.index {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.index .product-list-block__more-link {
  display: none;
}

.product-list-block .product-list-block__content ul {
  @media (--medium-plus) {
    text-align: center;
  }
}

.index {
 .subscription-form {
   margin-top: 1.5rem;
   padding: .5rem 1rem;
   border-top: 1px dashed var(--cl_gray_x-light);
   border-bottom: 1px dashed var(--cl_gray_x-light);

    @media (--medium-plus) {
      margin-top: -1px;
      margin-bottom: 1.5rem;
    }
 }
}

.link-to-product {
  margin: 1.5rem 0;
  font-size: var(--font_size_2);
}

.product-image {
  margin-bottom: 2rem;

  a {
    display: inline-block;
    border: 1px solid var(--cl_border_gray_x-light);
  }
}

.product-comments {
  list-style-type: none;
  padding: 0;
  margin: 0 0 3rem 0;
  font-size: var(--font_size_2);
}

.product-comment {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2rem;
  padding-top: 2rem;
  border-top: 1px dashed var(--cl_gray_x-light);

  &:before {
    display: block;
    min-width: 32px;
    content: '';
    margin-right: 10px;
    width: 32px;
    height: 32px;
    background: svg-load('i/icon_circle.svg') 0 0 no-repeat;
    opacity: .25;
  }
}

.product-comment-child {
  padding: 0 0 0 2rem;
  border-top: none;
  border-left: 2rem solid var(--cl_border_gray_x-light);
}

.product-comment-q {
  &:before {
    background-image: svg-load('i/icon_circle_question.svg');
    opacity: 1;
  }
}

.product-comment-r {
  &:before {
    background-image: svg-load('i/icon_circle_review.svg');
    opacity: 1;
  }
}

.product-comment-pa {
  &:before {
    background-image: svg-load('i/icon_circle_heart.svg');
    opacity: 1;
  }
}

.product-comment__meta {
  flex-grow: 100;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: var(--font_size_-1);
}

.product-comment__name {
  font-weight: 500;
  color: #000;
}

.product-comment__body {
  width: 100%;
  margin-top: 1rem;
  line-height: 1.6;
}

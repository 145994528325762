.about .text-block {
  margin: 3rem 0;
}

.delivery-info {
  margin: 3rem 0;
  line-height: 1.5;
}

.shipping-city {
  font-size: var(--font_size_4);
  font-weight: bold;
}

ol.shipping-methods {
  .collapsible:not(.is-collapsed) {
    font-weight: bold;
  }

  @media (--medium-plus) {
    .collapsed-content {
      padding-left: 3rem;
    }
  }
}

.shipping-method {
  margin: 3rem 0;
  padding-left: 1.5rem;
}

.shipping-method__hdr {
  font-size: var(--font_size_2);
  font-weight: bold;
}

.shipping-cost {
  @nest .delivery-info & {
    font-size: inherit;
  }
}

.shipping-note {
  font-size: smaller;
  color: var(--cl_gray);
}
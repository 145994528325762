.city-select {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
    padding: var(--gutter) var(--half-gutter);
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .3);
    background: #fff;

    @media (--medium-plus) {
        padding: var(--gutter);
        border-radius: 4px;
    }

    .btn {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.dropdown-search {
    position: relative;
    z-index: 10;
    display: inline-block;
    width: 100%;
    min-width: 230px;
    min-height: 3.7rem;
    padding: 1rem 3rem 1rem 1.4rem;
    border: 1px solid rgba(34, 36, 38, .2);
    border-radius: 3px;
    cursor: pointer;

    /* @media (--medium-plus) {
        min-width: auto;
    } */

    &.--open {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
    }

    select {
        display: none;
    }

    input.search {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 1px;
        width: 100%;
        padding: 1rem 3rem 1rem 1.4rem;
        border: none;
        cursor: text;
        background: none;
        outline: 0;
    }
}

.dropdown-search__text {
    pointer-events: none;
    position: relative;
    z-index: 3;
    left: 1px;
    display: inline-block;
    cursor: text;
    opacity: 1;

    @nest .--open & {
        opacity: .35;
    }

    /* @nest .--filtered & {
        visibility: hidden;
    } */

    &.--default {
        opacity: .75;

        @nest .--open & {
            opacity: .35;
        }
    }
}

.dropdown-search__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    width: calc(100% + 2px);
    margin: 0 -1px;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 300px;
    border: 1px solid rgba(34, 36, 38, .2);
    border-radius: 3px;
    background-color: #fff;

    .dropdown-search.--open & {
        display: block;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        height: 10px
    }

    &::-webkit-scrollbar-track {
        background: rgba(0,0,0,.1);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 5px;
        background: rgba(0,0,0,.25);
        -webkit-transition: color .2s ease;
        transition: color .2s ease
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(0,0,0,.15)
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(128,135,139,.8)
    }

    li {
        list-style-type: none;
        margin: 0;
        padding: 1rem 1.5rem;
        border-top: 1px solid #f0f0f0;
        white-space: nowrap;

        &.--selected {
            font-weight: 500;
            color: var(--cl_gray_dark);
            background: #f0f0f0 !important;
        }
    }

    li:focus,
    li:hover {
        background: #fafafa;
    }

    li[data-value="-1"] {
        display: none;
    }
}

.dropdown-search__dropdown.--empty li[data-value="-1"] {
    display: block !important;
}

.dropdown-search__icon {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    z-index: 3;
    transform: scaleY(0.75);

    &:before {
        content: '\25BC';
        font-size: var(--font_size_-3);
    }
}
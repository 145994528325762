.l-product__shop-actions {
  grid-area: shop;

  @media (--small) {
    margin-top: 1.5rem;
  }
}

.product__shop-actions {
  @media (--medium-plus) {
    display: block;
    padding: 0 2rem;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  @media (--large) {
    position: relative;
    margin-top: 0;
    padding: 1.5rem;
    border-top: 7px solid var(--cl_carmine);
    border-radius: 4px;
    text-align: center;
    background: var(--cl_bg_gray_light);
    line-height: inherit;

    &.out-of-stock {
      border-top-color: var(--cl_gray_x-light);
    }
  }

  .main-option {
    padding-bottom: 0;
  }

  /* .main-option .item {
    margin-bottom: 0;
  } */
  
}

.buy-form {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  max-width: 394px;

  @media (--medium-plus) {
    margin-top: 0;
  }

  @media (--large) {
    display: block;
    float: none;
  }

  @nest .product-archived & {
    flex-direction: row;
  }
}

.product-price {
  grid-area: price;
  flex-basis: 50%;
  display: inline-block;
  text-align: center;
  font-size: var(--font_size_10);
  transition: var(--transition_base);

  @media (--large) {
    display: inline-block;
    margin-bottom: 1rem;
  }

  span {
    line-height: 1;
  }

  &__amount {
    margin-right: .4rem;
  }

  &__currency {
    font-size: var(--font_size_2);

    @media (--large) {
      margin-left: 0;
    }
  }

  &__from {
    margin-right: 4px;
    font-size: var(--font_size_-1);
  }

  @nest .out-of-stock & {
    color: var(--cl_gray);
  }
}

.undiscounted-price {
  display: inline-block;
  position: relative;
  margin-right: .5rem;
  font-size: var(--font_size_2);
  color: var(--cl_gray_x-light);

  .product-price__amount {
    margin-right: 0;
  }

  .product-price__currency {
    display: none;
  }
}

.undiscounted-price:after {
  content: '';
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  border-top: 1px solid var(--cl_gray_x-light);
}

.product-price__discount {
  display: none;
  margin-left: .5rem;
  font-size: var(--font_size_-2);
}

.product-price__sale-price {
  /*
  display: flex;
  align-items: flex-end;
  */
  display: inline-block;
  color: var(--cl_text_error_red);

  @media (--large) {
  }

  .product-price__amount {
    line-height: .95;

    @media (--medium-plus) {
      line-height: .9;
    }
  }
}

.product-price-offers {
  flex-basis: 100%;
  margin-top: 1.5rem;
  text-align: center;
  font-size: var(--font_size_base);
  line-height: 1.4;
  color: var(--cl_gray);

  a {
    font-weight: bold;
  }
}

.price-offers-list {
  overflow: hidden;
  height: 0;
  margin-top: 1rem;
}

.price-offer {
  margin: .25rem 0;
}

.second-option {
  margin-top: 1.4rem;
}

.product-variants {
  grid-area: variants;

  @media (--large) {
    margin: 1rem 0;
  }

  select {
    width: 100%;
    min-width: 140px;
    font-size: var(--font_size_-2);
    border-radius: 4px;
  }

  fieldset {
    display: none;
  }

  &.is-selected fieldset {
    @media (--large) {
      display: block;
      margin: 0;
      text-align: center;
      padding: .5rem 0;
      border: none;
    }
  }

  legend {
    font-size: var(--font_size_-3);
    text-transform: uppercase;
  }

}

.priceoff-variant {
  flex-basis: 100%;
  display: inline-block;
  padding: 2px 4px;
  border: 1px solid;
  border-radius: 2px;
  font-size: var(--font_size_-1);
}

.product-action {
  flex-basis: 100%;
  margin-top: 2rem;

  grid-area: action;

  .cart-link {
    margin-top: 1.5rem;
    text-align: center;
    font-weight: bold;

    a:after {
      content: '🡒';
      margin-left: .5rem;
    }
  }
}

@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}

.btn-buy,
a.btn-inquiry {
  vertical-align: inherit;
  width: 100%;
  padding: 12px 8px;

  border: none;
  background: var(--cl_selling_green);
  color: white;

  font-size: var(--font_size_1);
  transition: var(--transition_base);

  @media (--large) {
    width: 100%;
  }

  &:hover {
    color: white;
    background-color: color-mod(var(--cl_selling_green) shade(10%));
  }

  &:active {
    animation-duration: 1s;
    animation-name: pulse;
  }

  &.in-process {
    opacity: .5;
    background-image:
      repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 10px,
        #ccc 10px,
        #ccc 20px
      );
    background-size: 400% 400%;
    animation: barberpole 5s linear infinite;
    cursor: default;
  }

  &.btn--processed {
    background-color: var(--cl_carmine);
    border-color: var(--cl_carmine);
    color: #fff;
    &:before {
      content: '✔';
      margin-right: 1rem;
    }
  }
}

.product__shop-actions .btn-buy[disabled] {
  border-color: var(--cl_gray_light) !important;
  color: var(--cl_gray_dark) !important;
  background-color: var(--cl_bg_gray) !important;
}

a.btn-inquiry {
  text-align: center;
  background-color: var(--cl_carmine);

  &:hover {
    color: white;
    background-color: color-mod(var(--cl_carmine) shade(10%));
  }
}

.product-stock {
  flex-basis: 50%;
}

.product-availability {
  grid-area: availability;
  display: inline-block;
  /*
  margin-bottom: .5rem;
  */
  padding: 2px 10px 4px 8px;
  white-space: nowrap;

  text-align: center;
  font-size: var(--font_size_base);
  line-height: normal;
  border: 1px solid var(--cl_border_green);
  border-radius: 4px;
  color: var(--cl_text_green);

  transition: var(--transition_base);

  &:before {
    content: "\2713";
    padding-right: .5rem;
  }

  @media (--large) {
    margin: 1rem 0;
    height: auto;
  }

  @nest .out-of-stock & {
    color: var(--cl_gray);
    border-color: var(--cl_gray_light);

    &:before {
      content: "\2715";
    }
  }

  @nest .priceoff & {
    color: var(--cl_text_error_red);
    border-color: var(--cl_border_error_red);

    &:before {
      content: "\2b87";
    }
  }

}

.product-last-stock,
.product-stock-request-link {
  flex-basis: 100%;
  margin-top: 1rem;
  color: var(--cl_gray);
  font-size: var(--font_size_base);
  text-align: center;

  @media (--large) {
    margin: 1.5rem 0 .5rem;
    font-size: var(--font_size_-1);
    line-height: 1.5;
  }
}

.product-last-stock__date {
  margin-left: .25rem;
  @media (--large) {
    margin-left: 0;
    display: block;
  }
}

.options-picker__hdr {
  display: none;

  @media (--large) {
    display: block;
  }
}

fieldset.color-spots {
  margin: 0;
  text-align: center;
  padding: 1.25rem;
  border: 1px solid var(--cl_gray_x-light);

  @media (--large) {
    border-color: var(--cl_gray_light);
  }

  @nest .product-variants--unselected & {
    border-color: var(--cl_border_error_red);

    legend {
      color: var(--cl_text_error_red);
    }
  }

  legend {
    padding: 0 .5rem;
    font-size: var(--font_size_-3);
    text-transform: uppercase;
  }
}

.color-spot {
  position: relative;
  display: inline-block;
  margin: 0 6px;
  width: 16px;
  height: 16px;
  border-radius: 8px;

  transition: transform 0.15s;

  &:hover {
    transform: scale(1.4);
  }
}

.color-spot--active {
  &:hover {
    transform: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px 0 0 -5px;
    width: 26px;
    height: 26px;
    border-radius: 26px;
    border: 2px solid var(--cl_border_green);
  }
}

.options-picker {
  display: none;
  @media (--large) {
    display: inherit;
  }
}

.product-variants__select {
  @nest .product-variants--unselected & {
    border-color: var(--cl_border_error_red);
    color: var(--cl_text_error_red);
  }

  @media (--large) {
    @nest .product-variants--visual & {
      display: none;
    }
  }
}

.product__priceoff-offer {
  margin-top: 1.5rem;
  padding: .5rem;
  text-align: center;
  line-height: 1.5;
  border-radius: 4px;
  background: var(--cl_bg_pink_light);

  @media (--medium-plus) {
    padding: 1rem;
  }

  a {
    font-weight: bold;
    @mixin a-perm;
  }
}

select.main-option,
select.second-option {
  transition: var(--transition_base);
}

select.is-awaiting-choose {
  background-color: #ffffe0;
}

.options-as-selector {
  .item {
      display: inline-block;
      min-width: 32px;
      margin-right: 6px;
      margin-bottom: 6px;
      padding: 4px 6px;
      border: 1.5px solid #d6d6d6;
      border-radius: 3px;
      font-size: var(--font_size_base);
      outline: 0;
      color: var(--cl_gray);
      background-color: #fff;
      cursor: pointer;

      @media (--x-small)  {
          min-width: 26px;
          padding: 3px 4px;
      }

      &.item.is-active {
          font-weight: bold;
          border-width: 1px;
          border-color: #1abc9c;
          color: white;
          background-color: #1abc9c;
      }

      &.item.is-disabled {
          position: relative;
          opacity: .6;
          border-style: dashed;
          color: #999;
          cursor: default;
          overflow: hidden;

          &:before {
              position: absolute;
              content: '';
              left: -2px;
              top: 50%;
              right: -2px;
              border-top: 1px solid;
              border-color: var(--cl_border_gray_light);
              transform: rotate(22deg);
          }
      }
  }
}

.shipping-title {
  margin-top: -1rem;
  margin-right: .5rem;
  font-weight: bold;
}

.shipping-cost {
  font-weight: bold;
  font-size: var(--font_size_1);
  color: var(--cl_text_green);

  del {
    color: var(--cl_gray_light);
  }
}

.shipping-cost:has(del) {
  color: var(--cl_text_sale_red);
}

input[disabled] ~ .shipping-title:not(.marker),
input[disabled] ~ .shipping-cost {
  font-weight: normal;
  color: var(--cl_text_disabled);
}

.point-selector-link {
  font-weight: bold;

  &:before {
    content: '';
    display: block;
  }

  .chosen-visible {
    display: none;
  }
}

.point-chosen.active {
  .point-selector-link .chosen-visible {
    display: inline;
  }

  .point-selector-link .chosen-invisible {
    display: none;
  }
}

.fs-shipping-method {
  @media (--medium-plus) {
    .radio_note p {
      margin: 0;
    }
  }
}

.checkout-form__method-chooser {
  display: none;
  gap: 1rem;
  margin: 1rem 0 .5rem;

  @nest li.active & {
    display: flex;
  }
}

.if-method--self {
  display: none;

  @nest [data-method=self] & {
    display: block;
  }
}

.if-method--courier {
  display: none;

  @nest [data-method=courier] & {
    display: block;
  }
}

.checkout-form__method-option {
  padding: .5rem 1rem;
  border: 1px solid var(--cl_border_gray);
  border-radius: 3px;
  cursor: pointer;

  .option-cost {
    margin-left: .5rem;
  }
}

.checkout-form__method-option--active {
  background: var(--cl_selling_green);
  border-color: var(--cl_selling_green);
  color: white;

  .option-cost {
    display: none;
  }
}

.pickpoint-postamat-data, .chosen-point-data {
  display: none;

  @media (--medium-plus) {
    margin: .25rem 0 !important;
  }

  br {
    @media (--medium-plus) {
      display: none;
    }
  }

}

.active.point-chosen .city-points-num {
  display: none;
}

.active .chosen-point-data,
.active .single-point-data {
  display: block;
  color: var(--cl_text_green);

  &:before {
    content: '\2713';
    margin-right: .5rem;
    font-weight: bold;
  }
}
.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  margin: 0 auto;
  min-width: var(--page_min_width);
  max-width: var(--page_max_width);

  padding: 0.5rem 1rem;

  line-height: 2rem;

  @media (--small) {
    flex-wrap: wrap;
  }

  @media (--medium-plus) {
    padding: 0.75rem var(--gutter);
  }

  a {
    color: var(--cl_gray);

    &:hover {
      color: var(--cl_gray_dark);
    }
  }
}

ul.top__menu {
  display: flex;
  /* align-items: center; */
  margin: 0;
  padding-left: 0;

  @media (--medium-plus) {
    flex-basis: 30%;
    flex-grow: 2;
    padding-right: var(--gutter);

    .top--no-promo &.top__menu {
      flex-basis: 70%;
    }
  }

  li {
    list-style-type: none;
    margin-right: .75rem;

    @media (--medium-plus) {
      margin-right: 1.5rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

ul.top__menu-right {
  justify-content: right;

  @media (--medium-plus) {
    flex-basis: 30%;
    padding-right: 0;
  }

  li {
    margin-right: 0;
    margin-left: .75rem;

    @media (--medium-plus) {
      margin-left: 1.5rem;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.top__promo {
  flex-basis: 40%;
  flex-grow: 2;
  white-space: nowrap;
  padding: 0 var(--half-gutter);
  text-align: center;
  font-weight: bold;

  @media (--medium-plus) {
    padding: 0 var(--gutter);
  }

  a {
    padding: 2px 8px;
    border-radius: 3px;
    font-weight: 500;
    color: white;
    background: #8360c3;
    background: -webkit-linear-gradient(to right, #2ebf91, #8360c3);
    background: linear-gradient(to right, #2ebf91, #8360c3);
    background: #77A1D3;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #E684AE, #79CBCA, #77A1D3);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #E684AE, #79CBCA, #77A1D3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  a:hover {
    border-bottom-color: transparent;
  }
}

/*
.top__promo {
  position: relative;

  @media (--small) {
    order: 5;
    flex-basis: 100%;
  }
}
*/

.top__acclink {
  i {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAQpJREFUeNrsVtENgjAUpDiAukE//ewGdgQ20A1kA0fQDXAD3QA3ECeQDWCDWpIj6Y9Q+h5pTHjJpQlpuKPcuz5hjEn+rZRFYVFaGKDEMzUn8cbi4pD+whl72evhQd7jzk2eTSDvkXEKaAMENJymM4EYNWXqIUASxEsOAYp4emQBFUFAxeEBSfCA5DJiHUBec7ahDhCgucPoNoH8Otd9kI+EUos93iUCTXlEiynH7RVOqU6W+qcSE/77Hqt2WtOtp7N2fnhT/aAwAX0ISfjCO7zvk26UOhFJh8Qchsa1HEOEmRkNPjI45bhQUHKeC3qF3N5F6sKtQH6vIwmoBY4iWqWxk3AREF3AV4ABALS/DmKAS/50AAAAAElFTkSuQmCC) no-repeat center;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAWhJREFUeNrsV4FtwyAQtDtBRvAIbFA2aDZINqg3aDdINog3qDdINgidIGxgb0CxdEivTzBg41iR+tLLyDn4N9zfk9IYU7yaCesn62frBn7GO7Fk4I31Awnq8y9gs1sbEdz5T+7gWxbgYl2S3yXeUcw2ZwI9WbgZwTUE1+UknVtUB853A4zDB0n5FpFARcYKuzG2U8ozd3ICgiUQMuWZOzmBpAUZOVUODlSMWCEOdARf5SKijqzxlhE2m0lW41frH/jCCuMrw8jcYtQkKOFxqX5QM1Hi3gMTbeVEUu5REYKwXWGXdPFvr2Rlwrm/4ykfKF6BduyeAx9+5/JB4AZ0Syg/80AvDilXtUFKP2cGHUtmNyblNdNxw2T1CIz0aLw7nhpY7Vmrw0cGVU5jsTkNZZj77Unm5NP5S+77HGwPct71C9rB2idU3l28fon+HXm/uJUYzOkNU8ykXMkWV8JV/52uvgOrJ/AnwABlYcsPuGsS/AAAAABJRU5ErkJggg==) no-repeat center;
    background-size: 14px 14px;
  }

  .top--no-promo & i {
    vertical-align: text-top;
  }

  a.icon {
    border: none;
  }

  @media (--medium-plus) {
    i {
      margin: 0 .75rem 4px 0;
    }
  }

  /*
  @media (--small) {
    a {
      position: absolute;
      overflow: hidden;
      margin: -1px;
      width: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0 0 0 0);
      border: 0;
    }
  }*/
}

@media (--small) {
  .top__profile-link {
    display: none;
  }
}

.top--no-promo {
  .top__acclink .icon {
    margin-right: .5rem;
  }

  .top__profile-link {
    display: inline;
  }
}

/*
.top__acclink--3:before {
  background: svg-load('i/user_discount_3.svg') no-repeat center;
}

.top__acclink--5:before {
  background: svg-load('i/user_discount_5.svg') no-repeat center;
}

.top__acclink--7:before {
  background: svg-load('i/user_discount_7.svg') no-repeat center;
}

.top__acclink--10:before {
  background: svg-load('i/user_discount_10.svg') no-repeat center;
}

.top__acclink--3,
.top__acclink--5,
.top__acclink--7,
.top__acclink--10 {
  &:before {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-top: -3px;
    background-size: 15px 15px;
  }
}
*/

.top__phone {
  white-space: nowrap;

  @media (--small) {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: .5rem 1rem;
    font-size: var(--font_size_1);
    font-weight: bold;
    background: #fffbe5;

    a {
      border-bottom-color: currentColor;
    }

    span {
      font-weight: normal;
    }
  }

  @media (--medium-plus) {
    flex-basis: 20%;
    text-align: center;
    font-size: var(--font_size_6);
  }

  &.activated {
    @media (--small) {
      display: inherit;
    }
  }

  span {
    @media (--medium-plus) {
      margin-right: 2px;
      font-size: var(--font_size_1);
      color: var(--cl_gray);
    }
  }
}

.top__phone__tip {
  display: none;
  margin-left: .75rem;
  font-size: var(--font_size_-3);
  color: var(--cl_gray);

  .activated & {
    display: inline;
  }
}

.top__icon {
  border: none;
  align-items: center;
  display: flex;

  &:hover {
    background: var(--cl_bg_gray_light);
  }

  @media (--medium-plus) {
    display: none;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  &.icon-phone .icon {
    background: svg-load('i/icon_phone.svg') no-repeat center;
  }

  &.icon-close {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -8px;

    .icon {
      background: svg-load('i/icon_close.svg') no-repeat center;
    }
  }
}

.xmas-tree-icon:before {
  content: '';
  display: inline-block;
  margin: -2px .5rem 0 0;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background: url(/static/i/xmas_tree@2x.png) no-repeat 50%;
  background-size: 16px 16px;

  @media (--medium-plus) {
    margin-right: .75rem;
    width: 23px;
    height: 24px;
    background-size: 23px 24px;
  }
}

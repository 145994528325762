.checkout-form {
  .invalid-feedback {
    margin: 0 0 1.5rem -3rem;
    padding: .5rem;
    text-align: center;
    font-size: var(--font_size_-2);
    text-transform: uppercase;
    color: red;
    background-color: var(--cl_bg_error_red);

    @media (--medium-plus) {
      margin: -1rem 0 2rem -4rem;
    }

    strong {
      &:before {
        content: '\2193';
        margin-right: .75rem;
      }

      &:after {
        content: '\2193';
        margin-left: .75rem;
      }
    }
  }
}

.proved-data {
  margin-bottom: 2rem;
  font-size: var(--font_size_-1);

  @media (--medium-plus) {
    display: flex;
    margin-bottom: 1rem;
  }
}

.proved {
  margin-bottom: 1rem;
  font-size: var(--font_size_1);

  @media (--medium-plus) {
    /*
    flex-basis: 0;
    flex-grow: 1;
    max-width: 25%;
    */
    margin: 0 8rem 0 0;
  }

  a {
    color: var(--cl_carmine);
  }
}

.proved__label {
  color: var(--cl_gray);
  margin-right: .25rem;

  /*
  &:after {
    content: ':';
    margin-right: .25rem;
  }
  */

  @media (--medium-plus) {
    font-size: var(--font_size_-2);
    margin-right: 0;

    &:after {
      content: none;
    }
  }
}

.proved__data {
  font-weight: bold;

  a:after {
    content: '\2716';
    margin-left: .5rem;
    /*
    color: white;
    */
  }

  @media (--medium-plus) {
    display: block;
    margin-top: .25rem;
    font-size: var(--font_size_1);
  }
}

.proved__note {
  display: block;
  margin-top: .5rem;
  font-size: var(--font_size_-2);
  color: var(--cl_text_green);
}

.checkout-closed-until {
  display: inline-block;
  margin: -1rem 0 1rem;
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
  font-size: var(--font_size_-11);
  line-height: 1.35;
  color: black;
  background-color: #f9ea8f;
  background-image: linear-gradient(315deg, #f9ea8f 0%, #aff1da 74%);
  background: linear-gradient(345deg, #ffdee9 0%, #b5fffc 100%);
}
.search__pre {
  margin-bottom: 2rem;
}

.search__query {
  @mixin highlighted;

  &:before {
    content: '\7b';
    margin: 0 .25rem;
  }

  &:after {
    content: '\7d';
    margin: 0 .25rem;
  }
}
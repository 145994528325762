.product__info {
  margin-top: 3rem;
  font-size: var(--font_size_2);

  @media (--medium-plus) {
    grid-area: info;
    margin-top: 4rem;
  }

  h2 {
    margin: 3rem 0 2rem;
    text-align: left;
    background-position: 0 40%;
  }
}

.c-tabs-nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (--medium-plus) {
    display: block;
  }
}

.c-tabs-nav__item {
  flex-grow: 100;
  margin-right: 1rem;
  padding: 1rem;
  font-size: var(--font_size_1);
  border: 2px solid var(--cl_carmine);
  border-radius: 4px;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
  color: var(--cl_gray);

  &:last-child {
    margin-right: 0;
  }

  &--active {
    position: relative;
    font-weight: bold;
    color: #fff;
    border-color: var(--cl_carmine);
    background: var(--cl_carmine);

    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      bottom: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--cl_carmine);
      margin: 0 0 -8px -4px;
    }

    @media (--small) {
      a {
        @mixin a-white;
        border: none;
      }
    }
  }

  @media (--x-small) {
    margin-right: 0;
  }

  @media (--medium-plus) {
    display: inline-block;
    margin: 0 1rem 0 0;
    padding: 0 2rem;
    border: 1px solid var(--cl_gray_x-light);
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    font-size: var(--font_size_2);
    line-height: 2;
    vertical-align: bottom;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      margin-bottom: -3px;
      line-height: 2.5;
      text-transform: uppercase;
      font-size: var(--font_size_3);
      font-weight: bold;
      border-width: 3px;
      border-color: var(--cl_carmine);
      background: white;

      &:after {
        content: unset;
      }
    }

    &-comments {
      background: var(--cl_carmine);
      border-color: var(--cl_carmine);

      a {
        @mixin a-white;
      }

      span {
        color: white;
      }
    }
  }
}

.c-tab {
  margin-bottom: 3rem;

  @media (--medium-plus) {
    padding: 1rem 3rem 3rem 3rem;
    border: 3px solid var(--cl_border_gray_x-light);
  }

  &--active {
    @media (--medium-plus) {
      border-color: var(--cl_carmine);
      border-radius: 4px;
    }
  }

  &--hidden {
    display: none;
  }

  h2 {
    font-size: var(--font_size_6);
    font-weight: normal;
    line-height: 1.4;
    color: var(--cl_gray_dark);

    @media (--medium-plus) {
      font-size: var(--font_size_8);
    }
  }

  .product__text,
  .product__properties {
    margin: 0 1.5rem 2rem 3rem;

    table.full-width {
        margin-left: -3rem;
        width: calc(100% + 4.5rem);
    }

    @media (--medium-plus) {
        margin: 0 4rem 2rem;

        table.full-width {
            margin-left: -4rem;
            width: calc(100% + 8rem);
        }
    }
  }
}

.product__text {
  line-height: 1.6;
}

.product-reviews-amount {
  margin-left: .5rem;
  vertical-align: top;
  font-size: var(--font_size_-3);
}

.product__comparisons {
  border-top: 2px dotted var(--cl_gray_x-light);
  border-bottom: 2px dotted var(--cl_gray_x-light);

  @media (--medium-plus) {
    margin: 0 -3rem;
    padding: .1rem 3rem 0;
  }

  .c-products {
    margin-top: 2rem;
  }

  .c-product {
    @media (--large) {
      flex: 0 1 25%;
    }
  }
}

.product__categories {
  ul {
    list-style-type: none;
    padding: 0;

    &.children {
      padding: 0 2rem;

      @media (--medium-plus) {
        padding: 0 4rem;
      }
    }
  }

  li {
    margin: 1rem 0;
  }

  .category-path {
    margin: 1.25rem 0;

    li {
      display: inline-block;
      margin-right: .5rem;

      &:not(:last-child)::after {
        content: "\2192";
        margin-left: .25rem;

        @media (--medium-plus) {
          margin-left: .5rem;
        }
      }
    }
  }
}

.product__video {
  position: relative;
  margin: 2rem 0;
  padding-bottom: 56.25%; /* обеспечивает нужный aspect ratio */
  height: 0;

  @nest .product__videos & {
    margin-top: 3rem;
  }
}

.product__video__v {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.link-to-form,
.link-to-comments {
  margin: 2rem 0 3rem;
  text-align: center;
  font-size: var(--font_size_4);
  font-weight: 500;
}

.link-to-comments {
  a {
    &:after {
      content: '\2192';
      margin-left: 1rem;
    }
  }

  &--anchored {
    a:after {
      content: unset;
    }
  }
}
.l-hat {
  min-width: var(--page_min_width);
  background: var(--cl_carmine) radial-gradient(color-mod(var(--cl_carmine_dark) a(80%)) 20%, transparent);
}

.hat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  height: 100%;

  max-width: var(--page_max_width);

  margin: 0 auto;

  padding: 1rem;

  @media (--medium-plus) {
    padding: 1rem var(--gutter);
  }

  @media (--large) {
  }

  @media (--large-more) {
  }
}

.hat__icon {
  border: none;
  align-items: center;
  display: flex;

  &:hover {
    background: var(--cl_carmine_dark);
  }

  @media (--medium-plus) {
    display: none;
  }

  .icon {
    margin: 8px;
    width: 24px;
    height: 24px;
  }
}

.icon-search .icon {
  background: svg-load('i/icon_search.svg') 50% 50% no-repeat;
}

.icon-cart {
  position: relative;

  .icon {
    background: svg-load('i/icon_shopping_basket.svg') 50% 50% no-repeat;
  }

  .cart-count {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 1.8rem;
    height: 1.8rem;
    line-height: 1.8rem;
    border-radius: 50%;
    text-align: center;
    color: white;
    background: black;
    font-size: var(--font_size_-4);
  }
}

/*
.icon-hamburger {
  background-position: -72px 0;
}
*/

.hat__search {
  display: none;

  @media (--medium-plus) {
    display: flex;
    align-items: center;
    flex-basis: 20%;
    padding-right: var(--gutter);
  }
}

.search-form {
  @media (--small) {
    display: none;
    padding: 1rem;
    background: var(--cl_bg_gray_light);
  }

  input[type=text] {
    width: 100%;
    height: 30px;
    padding: 0 .5rem 0 1rem;
    border: 1px solid var(--cl_gray_light);
    color: var(--cl_gray);
    background: var(--cl_bg_gray_light);
    opacity: .6;

    @media (--medium-plus) {
      text-indent: 3rem;
      border: 1px solid #fff;
      color: #fff;
      background: var(--cl_carmine);
      background: svg-load('i/icon_search.svg') .5rem 50% no-repeat;
    }

    &:focus {
      opacity: 1;
      text-indent: 0;
      background: none;

      &::placeholder {
        @media (--medium-plus) {
          color: transparent;
        }
      }
    }
  }

  input::placeholder {
    @media (--medium-plus) {
      color: #fff;
    }
  }

  &.isolated {
    display: block;
  }
}

.hat__logo {
  flex-grow: 999;
  text-align: center;

  @media (--medium-plus) {
    flex-grow: 0;
    flex-basis: 60%;
    align-items: center;
  }

  a {
    border: none;
  }

  img {
    margin-top: .25rem;
    width: 150px;

    @media (--medium-plus) {
      width: 200px;
    }
  }

  .tagline {
    text-transform: uppercase;
    font-size: var(--font_size_-5);
    font-weight: bold;
    letter-spacing: 0.25rem;
    line-height: 1;
    color: #fff;

    @media (--medium-plus) {
      font-size: var(--font_size_-4);
    }
  }
}

.hat__cart {
  display: none;

  @media (--medium-plus) {
    flex-basis: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

a.hat__cart-hdr {
  display: none;

  @media (--medium-plus) {
    display: block;
    padding-top: 27px;
    background: svg-load('i/icon_shopping_basket.svg') 50% 0 no-repeat;
    color: #fff;
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    border-bottom-color: currentColor;
  }
}

.hat__cart-msg {
  margin: 0 .25rem;
  opacity: .8;

  @nest .hat__cart-hdr:hover & {
    opacity: 1;
  }
}

.subscription-form {
  padding: .5rem 2.5rem 1.5rem;
  border: none;
  border-bottom: 1px solid var(--cl_gray_x-light);
  background: var(--cl_bg_gray_light);

  @media (--medium-plus) {
    margin-bottom: 2.5rem;
  }

  input, button {
    margin: .5rem;
  }

  input[name=email] {
    width: 20rem;
  }

  .btn-subscribe {
    padding: .7rem 2rem;
  }

  .base-form__hdr {
    margin-bottom: 1rem;
    padding: 0;
    border-bottom: none;
  }

  .base-form__help-text {
    margin: .5rem 0;
    line-height: 1.4;
    font-size: var(--font_size_-2);

    @media (--medium-plus) {
      margin: 1rem 0;
    }
  }
}

.product__shipping {
    display: flex;
    align-items: stretch;
    margin-top: 1rem;
    width: 100%;
    line-height: 1.5;

    @media (--medium-plus) {
        display: block;
        margin-top: 2rem;
    }
}

.product__shipping-city {
    flex-basis: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1rem;
    font-size: var(--font_size_1);

    @media (--medium-plus) {
        margin: .5rem 0;
        padding: 0;
        text-align: center;
    }

    &.mode--compact {
        font-size: var(--font_size_base);
        text-align: center;
    }

    &:before {
        content: '';
        margin-right: .5rem;
        width: 16px;
        height: 16px;
        opacity: .75;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' fill='%23e6242c' viewBox='0 -960 960 960' width='16'%3E%3Cpath d='M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 400Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Z'/%3E%3C/svg%3E");

        @media (--medium-plus) {
            margin-left: -8px;
        }
    }

    /* &.mode--min:before {
        content: none;
    } */

    /* a {
        border-bottom: none;
        text-decoration: underline dotted;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
    }

    a:hover {
        border-bottom: none;
        text-decoration: underline solid;
    } */
}

.product__shipping-options {
    flex-basis: 60%;
    padding-left: 1rem;
    border-left: 1px solid var(--cl_border_gray_light);

    @media (--medium-plus) {
        padding-left: 0;
        border: none;
        text-align: center;
    }
}

.product__shipping-option {
    margin: .25rem 0;
    padding: 0 .5rem;
    line-height: 1.6;

    @media (--medium-plus) {
        margin: 1rem 0;
    }

    &.--expanded {
        margin-bottom: .75rem;
        padding-bottom: .75rem;
        border-bottom: 1px dashed var(--cl_border_gray_light);
        line-height: 1.4;
    }

    .marked {
        display: inline-block;
        padding: 0 .5rem;
        text-transform: uppercase;
        font-size: var(--font_size_-3);
        font-weight: 400;
        vertical-align: text-top;
        letter-spacing: .75px;
        border-radius: 2px;
        color: #fff;
        background: #309;
    }
}

.selfmsk {
    margin-top: .5rem;
}